import React from "react";
import { BsQuestionCircle } from "react-icons/bs";
import { getColorStylesFromId } from "../../lib/functions";
import { PlayerGameStats, RatingsBreakdown } from "../../lib/models";
import { Tooltip } from "../general/tooltip";

interface EloBreakdownProps {
  ratingsBreakdown: RatingsBreakdown;
  playerStats: PlayerGameStats[];
}

export function EloBreakdown(props: EloBreakdownProps) {
  const { fraglimitMultiplier, numplayersMultiplier, ratingMatrixRows } = props.ratingsBreakdown;

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }} className="tooltip">
        <BsQuestionCircle style={{ marginRight: "8px", fontSize: "14px" }} />
        <div className="tooltiptext">
          ELO multipliers influenced by fraglimit and number of players in the match. Higher fraglimit and/or fewer players will increase
          ELO gained/lost.
        </div>
        <div style={{ margin: "16px 0" }}>
          <span>Fraglimit multiplier:</span>
          <span style={{ color: "lightgray", marginLeft: "8px" }}>{fraglimitMultiplier.toFixed(2)}</span>
          <span style={{ color: "lightgray", margin: "0 16px" }}>x</span>
          <span>#Players multiplier:</span>
          <span style={{ color: "lightgray", marginLeft: "8px" }}>{numplayersMultiplier.toFixed(2)}</span>
          <span style={{ color: "lightgray", margin: "0 16px" }}>=</span>
          <span style={{ color: "#02bfeb" }}>{(numplayersMultiplier * fraglimitMultiplier).toFixed(2)}</span>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          rowGap: "8px",
          gridTemplateColumns: `min-content repeat(${ratingMatrixRows.length + 1},min-content)`,
          columnGap: "16px",
        }}
      >
        <div>Name</div>
        {ratingMatrixRows.map((r) => (
          <div key={r.nickname}>{r.nickname}</div>
        ))}
        <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>ELO Delta</div>
        {ratingMatrixRows.map((r, i) => {
          const preDelta = r.delta / (fraglimitMultiplier * numplayersMultiplier);
          return (
            <React.Fragment key={r.nickname}>
              <div style={{ ...getColorStylesFromId(props.playerStats.find((ps) => ps.nickname === r.nickname)?.colorid).css }}>
                {r.nickname}
              </div>
              {r.versus.map((v: any, j: number) =>
                i === j ? (
                  <div key={v.nickname} />
                ) : (
                  <div key={v.nickname} style={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip
                      content={
                        <>
                          <div style={{ color: v.win > 0 ? "limegreen" : v.win === 0 ? "lightgray" : "red" }}>{v.win}</div>
                          <div style={{ marginRight: "2px", marginLeft: "6px", color: "lightgray" }}>+</div>
                          <div style={{ color: v.margin > 0 ? "limegreen" : v.margin === 0 ? "lightgray" : "red" }}>
                            {v.margin.toFixed(1)}
                          </div>
                        </>
                      }
                      tooltip={
                        v.win +
                        ` for ${v.win > 0 ? "winning" : "losing"} against ${v.nickname}. Additionally ${v.margin.toFixed(1)} ${
                          v.margin > 0 ? "won" : "lost"
                        } for gap to ${v.nickname}`
                      }
                      noIcon
                    />
                  </div>
                )
              )}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    color: preDelta > 0 ? "limegreen" : preDelta === 0 ? "lightgray" : "red",
                    width: "60px",
                    textAlign: "end",
                  }}
                >
                  {preDelta.toFixed(1)}
                </div>
                <div style={{ color: "lightgray", marginLeft: "4px" }}>
                  x<span style={{ color: "#02bfeb" }}>{(numplayersMultiplier * fraglimitMultiplier).toFixed(2)}</span>
                </div>
                <div style={{ color: "lightgray", marginLeft: "4px" }}>=</div>
                <div style={{ color: r.delta > 0 ? "limegreen" : r.delta === 0 ? "lightgray" : "red", width: "60px", textAlign: "end" }}>
                  {r.delta.toFixed(1)}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
