import React, { useContext } from "react";
import { DataContext } from "../../lib/context";
import { FONT_SIZE_LARGE, FONT_SIZE_MEDIUM, IMAGE_S3_URL } from "../../lib/definitions";
import { calculateWinner, getColorStylesFromId, getMatchTimestamp, getPrettyDuration, getPrettyMapName } from "../../lib/functions";
import { Match } from "../../lib/models";
import { Checkbox } from "../checkbox";

interface MetaSegmentProps {
  latestMatch: any;
  updateFavorites(matchId: string, add: boolean): void;
  match: Match;
}

export function MetaSegment(props: MetaSegmentProps) {
  const { mapProperties, favorites } = useContext(DataContext);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "min-content min-content auto",
        columnGap: "32px",
        rowGap: "8px",
        fontSize: FONT_SIZE_MEDIUM,
        color: "lightgrey",
        whiteSpace: "nowrap",
      }}
      className="small-font-border"
    >
      <div
        style={{
          fontSize: FONT_SIZE_LARGE,
          gridColumn: "span 3",
          color: "orange",
        }}
        className="first-header"
      >
        Meta
      </div>
      <div style={{ color: "orange" }}>Date:</div>
      <div style={{ textAlign: "end" }}>{getMatchTimestamp(props.match.timestamp)}</div>
      <div style={{ color: "orange" }}>Map:</div>
      <div style={{ textAlign: "end" }}>{getPrettyMapName(props.match.map, mapProperties)}</div>
      <div style={{ color: "orange" }}>Game type:</div>
      <div style={{ textAlign: "end" }}>
        {props.match.playerstats.length >= 5 ? "Mayhem" : props.match.playerstats.length >= 3 ? "FFA" : "1v1"}
      </div>
      <div style={{ color: "orange" }}>#Players:</div>
      <div style={{ textAlign: "end" }}>{props.match.playerstats?.length || "N/A"}</div>
      <div style={{ color: "orange" }}>Fraglimit:</div>
      <div style={{ textAlign: "end" }}>{props.match.fraglimit || "N/A"}</div>
      <div style={{ color: "orange" }}>Duration:</div>
      <div style={{ textAlign: "end" }}>{getPrettyDuration(props.match.gametime)}</div>
      <div style={{ color: "orange" }}>Host:</div>
      <div
        style={{
          textAlign: "end",
          ...getColorStylesFromId(props.match.playerstats.find((p) => p.nickname === props.match.hostNickname)?.colorid).css,
        }}
      >
        {props.match.hostNickname || "N/A"}
      </div>
      <div style={{ color: "orange" }}>Winner:</div>
      <div style={{ textAlign: "end", ...getColorStylesFromId(calculateWinner(props.match)?.colorid).css }}>
        {calculateWinner(props.match)?.nickname || "N/A"}
      </div>
      <div style={{ color: "orange" }}>Favorite:</div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Checkbox
          checked={favorites.includes((props.latestMatch || props.match)._id + "")}
          onClick={() => {
            props.updateFavorites(
              (props.latestMatch || props.match)._id + "",
              !favorites.includes((props.latestMatch || props.match)._id + "")
            );
          }}
        />
      </div>
      <div
        style={{
          gridRow: "2/10",
          gridColumn: "3",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <img
          src={IMAGE_S3_URL + "thumbnails/" + mapProperties.find((mp) => new RegExp(mp.test).test(props.match.map))?.imageUrl}
          style={{ width: "400px", filter: "brightness(180%)" }}
          alt=""
        ></img>
      </div>
    </div>
  );
}
