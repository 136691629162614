import React, { CSSProperties } from "react";
import { Spinner } from "../spinner";

interface ButtonProps {
  text: string;
  disabled?: boolean;
  style?: CSSProperties;
  loading?: boolean;
  small?: boolean;
  onClick?(): void;
}

export function Button(props: ButtonProps) {
  return (
    <button
      style={{
        padding: props.small ? "6px 12px" : undefined,
        fontSize: props.small ? "16px" : undefined,
        whiteSpace: "nowrap",
        ...props.style,
      }}
      onClick={() => {
        if (!props.disabled && props.onClick) {
          props.onClick();
        }
      }}
      className={"button" + (props.disabled ? " button__disabled" : "")}
    >
      {props.loading ? <Spinner small /> : props.text}
    </button>
  );
}
