import React from "react";
import { BsQuestionCircle } from "react-icons/bs";

interface TooltipProps {
  content: React.ReactNode;
  tooltip: React.ReactNode;
  noIcon?: boolean;
}

export function Tooltip(props: TooltipProps) {
  return (
    <div className="tooltip">
      {!props.noIcon && <BsQuestionCircle style={{ marginRight: "8px", fontSize: "14px" }} />}
      {props.content}
      <div className="tooltiptext">{props.tooltip}</div>
    </div>
  );
}
