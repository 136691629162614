import { Ratings } from "./models";

export const FONT_SIZE_VERYLARGE = "40px";
export const FONT_SIZE_LARGE = "30px";
export const FONT_SIZE_MEDIUM = "24px";
export const FONT_SIZE_SMALL = "18px";

export const DOMAIN = "ckal.dk";
export const BACKEND_URL = `https://api.${DOMAIN}/duke/`;
export const IMAGE_S3_URL = "https://duke-assets.s3.eu-west-1.amazonaws.com/public/images/";
export const BACKEND_SOCKET = `wss://${DOMAIN}`;

export const LF_CREDENTIALS = "dukedm_credentials";
export const AWS_CLIENT_ID = "6nki0f24aj9hrvluekbmkea631";

export enum APIResources {
  Matches = "matches",
  Players = "players",
  Patches = "patches",
  Maps = "maps",
  Me = "me",
  MeFavorites = "me/favorites",
  MeDonateELO = "me/donate-elo",
  LOVMapNameProperties = "lov/map-properties",
}

export const LF_PATCH = "duke_patch_read_date";

export const startColorIds = [16, 0, 5, 10, 21, 14, 11, 13, 12, 15, 23];
export const exclusiveColorIds = [24, 17, 20, 19, 8, 7, 2, 6, 4];

export const killLimits: Ratings = {
  duel: 200,
  ffa: 300,
  mayhem: 400,
};
