import React, { useEffect, useMemo, useState } from "react";
import { getProp, getPropString } from "../lib/functions";

export interface TableColumn {
  title: React.ReactNode;
  index: string;
  align?: "left" | "center" | "right";
  sort?: "string" | ((rowA: any, rowB: any) => number);
  defaultSortDirection?: SortDirection;
  relaxTitle?: boolean;
  custom?(value: any, row: any, rows: any[]): any;
}

interface TableProps {
  rowKey: string;
  rows: any[];
  columns: TableColumn[];
  noAutoSizeFirstColumn?: boolean;
}

type SortDirection = "Ascending" | "Descending";

export function Table(props: TableProps) {
  const [sortedColumn, setSortedColumn] = useState<TableColumn>();
  const [sortDirection, setSortDirection] = useState<SortDirection>("Ascending");

  useEffect(() => {
    if (!sortedColumn) {
      const col = props.columns.find((c) => c.defaultSortDirection);
      if (col) {
        setSortDirection(col.defaultSortDirection!);
        setSortedColumn(col);
      }
    }
  }, [props.columns, sortedColumn]);

  const sortedRows = useMemo(() => {
    if (sortedColumn) {
      return props.rows.sort((a, b) => {
        let result = 0;

        if (sortedColumn.sort === "string") {
          result = getPropString(a, sortedColumn.index).localeCompare(getPropString(b, sortedColumn.index));
        } else if (sortedColumn.sort) {
          result = sortedColumn.sort(a, b);
        } else {
          result = getProp(a, sortedColumn.index) - getProp(b, sortedColumn.index);
        }

        return result * (sortDirection === "Ascending" ? 1 : -1);
      });
    } else {
      return props.rows;
    }
  }, [props.rows, sortedColumn, sortDirection]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `${props.noAutoSizeFirstColumn ? "" : "auto"} repeat(${
          props.columns.length - (props.noAutoSizeFirstColumn ? 0 : 1)
        }, min-content)`,
        columnGap: "24px",
        rowGap: "8px",
        color: "lightgray",
      }}
    >
      {props.columns.map((c, i) => (
        <div
          key={i}
          style={{
            color: "orange",
            cursor: "pointer",
            userSelect: "none",
            textAlign: c.relaxTitle ? "center" : undefined,
            alignSelf: c.relaxTitle ? "center" : undefined,
            whiteSpace: c.relaxTitle ? undefined : "nowrap",
            wordWrap: c.relaxTitle ? "break-word" : undefined,
          }}
          onClick={() => {
            if (sortedColumn?.title === c.title) {
              setSortDirection(sortDirection === "Ascending" ? "Descending" : "Ascending");
            } else {
              setSortDirection("Ascending");
              setSortedColumn(c);
            }
          }}
        >
          {c.title}
        </div>
      ))}
      {sortedRows.map((r) => (
        <React.Fragment key={getProp(r, props.rowKey)}>
          {props.columns.map((c, i) => (
            <div key={i} style={{ textAlign: c.align || "end", whiteSpace: "nowrap" }}>
              {c.custom ? c.custom(getProp(r, c.index), r, sortedRows) : getProp(r, c.index)}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}
