import React, { useContext, useMemo } from "react";
import {
  playerGeneralStatisticsColumns,
  playerFeatsStatisticsColumns,
  playerDmgStatisticsColumns,
  weaponsStatsColumns1,
  getMapStatisticsColumns,
  weaponsStatsColumns2,
} from "../../lib/columns";
import { DataContext } from "../../lib/context";
import { FONT_SIZE_MEDIUM } from "../../lib/definitions";
import { calculateMapStats, calculateOverallStats, getPrettyDuration } from "../../lib/functions";
import { BackgroundBox } from "../backgroundBox";
import { Table } from "../table";

export function StatisticsPane() {
  const { matches, isFetching, mapProperties, playerStats, players } = useContext(DataContext);

  const overallStats = useMemo(() => calculateOverallStats(matches, players), [matches, players]);

  const mapStats = useMemo(() => calculateMapStats(matches, mapProperties), [matches, mapProperties]);

  return (
    <BackgroundBox header="All time statistics" loading={isFetching && matches.length === 0}>
      <div className="header">Overall</div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto min-content",
          columnGap: "32px",
          rowGap: "8px",
          fontSize: FONT_SIZE_MEDIUM,
          color: "lightgrey",
          whiteSpace: "nowrap",
          width: "400px",
        }}
        className="small-font-border"
      >
        <div style={{ color: "orange" }}># Matches:</div>
        <div style={{ textAlign: "end" }}>{overallStats.noMatches}</div>
        <div style={{ color: "orange" }}>Time played:</div>
        <div style={{ textAlign: "end" }}>{getPrettyDuration(overallStats.timePlayed)}</div>
        <div style={{ color: "orange" }}>Kills:</div>
        <div style={{ textAlign: "end" }}>{overallStats.kills}</div>
        <div style={{ color: "orange" }}>Suicides:</div>
        <div style={{ textAlign: "end" }}>{overallStats.suicides}</div>
        <div style={{ color: "orange" }}>Dmg dealt:</div>
        <div style={{ textAlign: "end" }}>{(overallStats.dmgDealt / 1000).toFixed(1)}K</div>
      </div>
      <div className="header" style={{ marginTop: "48px" }}>
        Player statistics
      </div>
      <Table rows={[...playerStats]} rowKey="nickname" columns={playerGeneralStatisticsColumns} noAutoSizeFirstColumn />
      <div style={{ height: "24px" }} />
      <Table rows={[...playerStats]} rowKey="nickname" columns={playerDmgStatisticsColumns} noAutoSizeFirstColumn />
      <div style={{ height: "24px" }} />
      <Table rows={[...playerStats]} rowKey="nickname" columns={playerFeatsStatisticsColumns} noAutoSizeFirstColumn />
      <div className="header" style={{ marginTop: "48px" }}>
        Map statistics
      </div>
      <Table rows={mapStats} rowKey="name" columns={getMapStatisticsColumns(mapProperties)} />
      <div className="header" style={{ marginTop: "48px" }}>
        Weapon usage
      </div>
      <Table rows={[...playerStats]} rowKey="nickname" columns={weaponsStatsColumns1} noAutoSizeFirstColumn />
      <div style={{ marginTop: "24px" }} />
      <Table rows={[...playerStats]} rowKey="nickname" columns={weaponsStatsColumns2} noAutoSizeFirstColumn />
    </BackgroundBox>
  );
}
