import React, { useContext, useMemo } from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from "recharts";
import { DataContext } from "../../lib/context";
import { calculateMyRatingsData, getColorStylesFromId } from "../../lib/functions";

export function SimpleRatingChart() {
  const { player, matches } = useContext(DataContext);

  const data = useMemo(() => calculateMyRatingsData(matches, player), [matches, player]);

  return (
    <ResponsiveContainer width="100%" height={80}>
      <LineChart
        margin={{
          top: 30,
        }}
        data={data}
      >
        <YAxis domain={["dataMin", "dataMax"]} hide />
        <Line dataKey="master" dot={false} connectNulls stroke={getColorStylesFromId(player?.colorId).primary} />
        <Tooltip
          formatter={(v: number) => [v.toFixed(0), "Master"]}
          labelFormatter={(l) => "Match " + (l + 1)}
          itemStyle={{ color: "lightgray" }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
