import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { MenuItem } from "./components/menuItem";
import { MatchOverviewPane } from "./components/panes/matchOverviewPane";
import { MapProperties, Match, Player, PlayerRatingWDelta } from "./lib/models";
import { APIResources, LF_PATCH } from "./lib/definitions";
import { calculateStats, doFetch } from "./lib/functions";
import { ws } from "./lib/websocket";
import * as localForage from "localforage";
import { StatisticsPane } from "./components/panes/statisticsPane";
import { PlayerRatingsPane } from "./components/panes/playerRatingsPane";
import { PatchesPane } from "./components/panes/patchesPane";
import { MyPagePane } from "./components/panes/myPagePane";
import { DataContext } from "./lib/context";
import { ChangeSkinPane } from "./components/panes/changeSkinPane";
import { BackgroundBox } from "./components/backgroundBox";
import { AboutPane } from "./components/panes/aboutPane";
import { DownloadsPane as DownloadPane } from "./components/panes/downloadsPane";
import { MatchHistoryPane } from "./components/panes/matchHistoryPane";
import { MapsPane } from "./components/panes/mapsPane";
import { DonateEloPane } from "./components/panes/donateEloPange";

interface MainProps {
  player: Player;
  onLogout(): void;
}

export function Main(props: MainProps) {
  const [matches, setMatches] = useState<Match[]>([]);
  const [players, setPlayers] = useState<PlayerRatingWDelta[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isNewPatch, setIsNewPatch] = useState(false);
  const [patches, setPatches] = useState<{ timestamp: number; note: string }[]>([]);
  const [mapProperties, setMapProperties] = useState<MapProperties[]>([]);
  const [favorites, setFavorites] = useState<string[]>([]);

  const refreshFavorites = useCallback(async () => {
    await doFetch("GET", APIResources.MeFavorites, setFavorites, console.log);
  }, []);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    await Promise.allSettled([
      doFetch("GET", APIResources.Players, setPlayers, console.log),
      doFetch("GET", APIResources.Matches, setMatches, console.log),
      doFetch("GET", APIResources.LOVMapNameProperties, setMapProperties, console.log),
      refreshFavorites(),
    ]);
    setIsFetching(false);
  }, [refreshFavorites]);

  useEffect(() => {
    fetchData();

    doFetch("GET", APIResources.Patches, setPatches, console.log);

    ws.onmessage = fetchData;
  }, [fetchData]);

  useEffect(() => {
    if (patches.length === 0) {
      return;
    }

    localForage.getItem(LF_PATCH, (_err, val) => setIsNewPatch(Number(val) < patches.length));
  }, [patches]);

  const playerStats = useMemo(
    () =>
      calculateStats(matches, players).map((ps) => ({
        ...ps,
        rating: players.find((pr) => pr.nickname === ps.nickname)?.masterRating,
      })),
    [matches, players]
  );

  function updateFavorites(matchId: string, add: boolean) {
    setFavorites((favs) => (add ? favs.concat(matchId) : favs.filter((f) => f !== matchId)));

    doFetch(
      add ? "POST" : "DELETE",
      APIResources.MeFavorites + "/" + matchId,
      () => doFetch("GET", APIResources.Players, setPlayers, console.log),
      console.log
    );
  }

  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
      <DataContext.Provider
        value={{
          player: players.find((p) => p.nickname === props.player.nickname) || props.player,
          players,
          matches,
          isFetching,
          playerStats,
          favorites,
          mapProperties,
          refreshFavorites,
          refresh: fetchData,
        }}
      >
        <div
          id="container"
          style={{
            display: "grid",
            gridTemplateColumns: "300px auto 300px",
            columnGap: "48px",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div style={{ padding: "24px 0 0 36px", position: "fixed" }}>
            <MenuItem text="Me" path="/me" highlightPaths={["me", "me/change-skin"]} />
            <MenuItem text="Latest" path="/latest" highlightPaths={["latest"]} />
            <MenuItem text="Matches" path="/matches" highlightPaths={["matches", "match"]} />
            <MenuItem text="Ratings" path="/ratings" highlightPaths={["ratings"]} />
            <MenuItem text="Statistics" path="/statistics" highlightPaths={["statistics"]} />
            <MenuItem text="Patches" path="/patches" highlightPaths={["patches"]} bubble={isNewPatch} />
            <MenuItem text="Maps" path="/maps" highlightPaths={["maps"]} />
            <MenuItem text="Download" path="/download" highlightPaths={["download"]} />
            <MenuItem text="About" path="/about" highlightPaths={["about"]} />
          </div>
          <div />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/me" />} />
              <Route exact path="/me" render={() => <MyPagePane onLogout={props.onLogout} />} />
              <Route exact path="/me/change-skin" render={() => <ChangeSkinPane />} />
              <Route exact path="/me/donate-elo" render={() => <DonateEloPane />} />
              <Route exact path="/latest" render={() => <MatchOverviewPane latestMatch={matches[0]} updateFavorites={updateFavorites} />} />
              <Route exact path="/ratings" component={PlayerRatingsPane} />
              <Route
                exact
                path="/matches"
                render={() => (
                  <BackgroundBox header="Match history" loading={isFetching && matches.length === 0}>
                    <MatchHistoryPane />
                    <MatchHistoryPane isFavorites />
                  </BackgroundBox>
                )}
              />
              <Route exact path="/match/:id" render={() => <MatchOverviewPane updateFavorites={updateFavorites} />} />
              <Route exact path="/statistics" component={StatisticsPane} />
              <Route exact path="/patches" render={() => <PatchesPane patches={patches} />} />
              <Route exact path="/maps" render={() => <MapsPane />} />
              <Route exact path="/download" render={() => <DownloadPane />} />
              <Route exact path="/about" component={AboutPane} />
              <Route render={() => <Redirect to="/me" />} />
            </Switch>
          </div>
          <div />
        </div>
      </DataContext.Provider>
    </div>
  );
}
