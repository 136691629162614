import React, { useContext, useMemo } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from "recharts";
import { DataContext } from "../lib/context";
import { getWeaponImageUrl } from "../lib/functions";

export function WeaponUsageChart() {
  const { playerStats, player } = useContext(DataContext);

  const weaponsStats = useMemo(() => {
    const myStats = playerStats.find((ps) => ps.nickname === player.nickname);

    if (!myStats) {
      return [];
    }

    return [
      { Primary: myStats.weapon1kills || 0, Alternative: myStats.weapon101kills || 0 },
      { Primary: myStats.weapon2kills || 0, Alternative: myStats.weapon102kills || 0 },
      { Primary: myStats.weapon3kills || 0, Alternative: myStats.weapon103kills || 0 },
      { Primary: myStats.weapon4kills || 0, Alternative: myStats.weapon104kills || 0 },
      { Primary: myStats.weapon5kills || 0, Alternative: myStats.weapon105kills || 0 },
      { Primary: myStats.weapon6kills || 0, Alternative: myStats.weapon106kills || 0 },
      { Primary: myStats.weapon7kills || 0, Alternative: myStats.weapon107kills || 0 },
      { Primary: myStats.weapon8kills || 0, Alternative: myStats.weapon108kills || 0 },
      { Primary: myStats.weapon9kills || 0, Alternative: myStats.weapon109kills || 0 },
      { Primary: myStats.weapon10kills || 0, Alternative: myStats.weapon110kills || 0 },
      { Primary: myStats.weapon50kills || 0, Alternative: 0 },
    ];
  }, [playerStats, player]);

  return (
    <div style={{ height: "200px" }} className="move-legend">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={500} height={300} data={weaponsStats}>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelFormatter={(l) =>
              [
                "Duke's mighty foot",
                "Pistol",
                "Shotgun",
                "Chaingun",
                "RPG",
                "Pipebomb",
                "Shrinker",
                "Devastator",
                "Tripbomb",
                "Freezer",
                "Flashbang",
              ][l]
            }
          />
          <Legend verticalAlign="top" />
          <Bar dataKey="Primary" stackId="a" fill="#82ca9d" />
          <Bar dataKey="Alternative" stackId="a" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(11,1fr)", marginTop: "16px" }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 50].map((n) => (
          <img
            key={n}
            src={getWeaponImageUrl(n)}
            alt=""
            style={{
              height: "18px",
              margin: "0 auto 0 auto",
              filter: "brightness(150%)",
            }}
          />
        ))}
      </div>
    </div>
  );
}
