import React, { useMemo } from "react";
import { getColorStylesFromId, getPrettyDuration, getWeaponImageUrl } from "../../lib/functions";
import { FragEvent, PlayerGameStats } from "../../lib/models";

interface FragHistoryProps {
  fragHistory: FragEvent[];
  playerStats: PlayerGameStats[];
}

export function FragHistory(props: FragHistoryProps) {
  const modifiedFragHistory = useMemo(
    () =>
      props.fragHistory
        .map((frag) => ({
          ...frag,
          playerColor: props.playerStats.find((p) => p.nickname === frag.nickname)?.colorid,
          killedByColor: props.playerStats.find((p) => p.nickname === frag.killedby)?.colorid,
        }))
        .sort((a, b) => a.gametimecounter - b.gametimecounter),
    [props.fragHistory, props.playerStats]
  );

  return (
    <div>
      <div></div>
      <div style={{ height: "500px", overflowY: "scroll", direction: "rtl", paddingLeft: "16px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "min-content min-content min-content min-content auto",
            rowGap: "8px",
            color: "lightgray",
            direction: "ltr",
          }}
        >
          {modifiedFragHistory.map((frag) => (
            <React.Fragment key={JSON.stringify(frag)}>
              <div style={{ textAlign: "end" }}>{getPrettyDuration(frag.gametimecounter, true)}</div>
              <div
                style={{
                  marginLeft: "30px",
                  ...getColorStylesFromId([51, 52].includes(frag.weapon) ? frag.playerColor : frag.killedByColor).css,
                }}
              >
                {[51, 52].includes(frag.weapon) ? frag.nickname : frag.killedby}
              </div>
              {frag.weapon === 51 ? (
                <div style={{ gridColumn: "span 2", textAlign: "center", marginLeft: "30px", marginRight: "8px", whiteSpace: "nowrap" }}>
                  Fell to death
                </div>
              ) : frag.weapon === 52 ? (
                <div style={{ gridColumn: "span 2", textAlign: "center", marginLeft: "30px", marginRight: "8px" }}>Squish</div>
              ) : frag.weapon > 200 ? (
                <div style={{ gridColumn: "span 2", textAlign: "center", marginLeft: "30px", marginRight: "8px" }}>Unknown</div>
              ) : (
                <>
                  <div style={{ marginLeft: "30px", marginRight: "8px" }}>{frag.weapon > 100 ? "ALT" : ""}</div>
                  <img
                    src={getWeaponImageUrl(frag.weapon)}
                    alt=""
                    style={{
                      height: "24px",
                      margin: "0 auto 0 auto",
                      filter: "brightness(150%)",
                    }}
                  />
                </>
              )}
              {[51, 52].includes(frag.weapon) ? (
                <div />
              ) : (
                <div style={{ marginLeft: "30px", ...getColorStylesFromId(frag.playerColor).css }}>{frag.nickname}</div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
