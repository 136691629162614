import { createContext } from "react";
import { MapProperties, Match, Player, PlayerStatistics } from "./models";

export const DataContext = createContext<{
  player: Player;
  players: Player[];
  matches: Match[];
  isFetching: boolean;
  playerStats: PlayerStatistics[];
  favorites: string[];
  mapProperties: MapProperties[];
  refreshFavorites(): void;
  refresh(): void;
}>({
  player: { email: "", nickname: "", colorId: 0, ratings: { ffa: 0, duel: 0, mayhem: 0 }, masterRating: 0 },
  players: [],
  matches: [],
  isFetching: false,
  playerStats: [],
  favorites: [],
  mapProperties: [],
  refreshFavorites: () => {},
  refresh: () => {},
});
