import React, { useEffect, useState } from "react";
import { LoginPane } from "./components/panes/loginPane";
import { getUserInfo } from "./lib/credentialsHandler";
import { Player, UserInfo } from "./lib/models";
import { Main } from "./main";
import { doFetch } from "./lib/functions";
import { APIResources, LF_CREDENTIALS } from "./lib/definitions";
import * as localForage from "localforage";
import { Spinner } from "./components/spinner";
import { CreateDukeProfilePane } from "./components/panes/createDukeProfilePane";
import { useHistory, useLocation } from "react-router-dom";

export function App() {
  const [player, setPlayer] = useState<Player>();
  const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [showNewProfile, setShowNewProfile] = useState(false);
  const [isGettingProfile, setIsGettingProfile] = useState(false);
  const [redirectedFromClient, setRedirectedFromClient] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(updateUserInfo, []);

  useEffect(() => {
    document.getElementById("container")?.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (userInfo) {
      fetchPlayer();
    }
  }, [userInfo]);

  function fetchPlayer() {
    setIsGettingProfile(true);
    doFetch(
      "GET",
      APIResources.Me,
      setPlayer,
      (res) => {
        if (res === "Not Found") {
          setShowNewProfile(true);
        }
        console.log(res);
      },
      () => setIsGettingProfile(false)
    );
  }

  function updateUserInfo() {
    const refreshToken = new URLSearchParams(window.location.search).get("token") || "";
    if (!refreshToken) {
      setIsGettingUserInfo(true);
      getUserInfo()
        .then(setUserInfo)
        .catch(() => {})
        .finally(() => setIsGettingUserInfo(false));
    }
  }

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      {showNewProfile ? (
        <CreateDukeProfilePane
          onBack={() => setShowNewProfile(false)}
          onSuccess={() => {
            setShowNewProfile(false);
            fetchPlayer();
            history.push("/me");
            if (redirectedFromClient) {
              alert("You have successfully registered your Duke profile and can now return to the Duke DM desktop client.");
            }
          }}
        />
      ) : isGettingUserInfo || isGettingProfile ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "200px" }}>
          <Spinner />
        </div>
      ) : player ? (
        <Main
          player={player}
          onLogout={() => {
            setUserInfo(undefined);
            setPlayer(undefined);
            localForage.setItem(LF_CREDENTIALS, "");
          }}
        />
      ) : (
        <LoginPane setRedirectedFromClient={setRedirectedFromClient} onLogin={setUserInfo} isLogginIn={isGettingUserInfo} />
      )}
      <div
        style={{
          position: "absolute",
          bottom: "2%",
          right: "2%",
          color: "darkgray",
        }}
      >
        {process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
}
