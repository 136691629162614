import React, { useMemo, useState } from "react";
import { ResponsiveContainer, BarChart, Bar, YAxis, XAxis } from "recharts";
import { getTicksFromMaxDamage } from "../../lib/functions";
import { Match } from "../../lib/models";

interface DamageChartProps {
  match: Match;
}

export function DamageChart(props: DamageChartProps) {
  const [mode, setMode] = useState<"" | "actual">("");

  const maxDamage = useMemo(
    () =>
      props.match.playerstats.reduce(
        (prev, curr) =>
          Math.max(
            prev,
            mode === "actual" ? curr.actualdamagedonetotal : curr.damagedonetotal,
            mode === "actual" ? curr.actualdamagetakentotal : curr.damagetakentotal
          ),
        0
      ),
    [props.match, mode]
  );

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", margin: "24px 0", columnGap: "40px" }}>
        <div onClick={() => setMode("")} style={{ color: mode === "" ? undefined : "lightgray", cursor: "pointer" }}>
          Dealt/Taken/Mitigated
        </div>
        <div onClick={() => setMode("actual")} style={{ color: mode === "actual" ? undefined : "lightgray", cursor: "pointer" }}>
          Actual Dealt/Actual Taken/Mitigated
        </div>
      </div>
      <ResponsiveContainer width="100%" height={props.match.playerstats.length * 90 + 80}>
        <BarChart
          layout="vertical"
          margin={{
            left: 100,
            right: 40,
          }}
          data={props.match.playerstats.filter((ps) => !ps.left).sort((a, b) => b.currentscore - a.currentscore)}
          barGap={5}
        >
          <XAxis type="number" ticks={getTicksFromMaxDamage(maxDamage)} />
          <YAxis dataKey="nickname" type="category" />
          <Bar dataKey={`${mode}damagedonetotal`} fill="#4393ed" barSize={25} />
          <Bar dataKey={`${mode}damagetakentotal`} fill="#df2f2d" barSize={25} />
          <Bar dataKey={`damagemitigation`} fill="#00ff74" barSize={25} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
