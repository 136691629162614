import React, { useEffect, useState } from "react";
import { LF_PATCH } from "../../lib/definitions";
import { BackgroundBox } from "../backgroundBox";
import * as localForage from "localforage";
import { Bubble } from "../bubble";
import { getPatchTimestamp } from "../../lib/functions";

interface PatchesPaneProps {
  patches: { timestamp: number; note: string }[];
}

export function PatchesPane(props: PatchesPaneProps) {
  const [latestPatchRead, setLatesPatchRead] = useState(0);

  useEffect(() => {
    if (props.patches.length === 0) {
      return;
    }
    localForage.getItem(LF_PATCH, (_err, val) => setLatesPatchRead(Number(val)));
    localForage.setItem(LF_PATCH, props.patches.length);
  }, [props.patches.length]);

  return (
    <BackgroundBox header="Patch history" style={{ maxWidth: "1000px" }}>
      {props.patches.map(({ timestamp, note }, index) => (
        <div key={timestamp}>
          <div style={{ display: "flex", alignItems: "center", marginTop: index ? "32px" : undefined }}>
            {props.patches.length - index > latestPatchRead && <Bubble style={{ marginRight: "16px" }} />}
            <div style={{ fontSize: "30px" }}>{getPatchTimestamp(timestamp)}</div>
          </div>
          <div style={{ color: "lightgray", fontSize: "18px" }}>
            <ul>
              {note.split(". ").map((n, i) => (
                <li style={{ marginBottom: i !== note.split(". ").length - 1 ? 8 : 0 }}>{n}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </BackgroundBox>
  );
}
