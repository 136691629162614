import React, { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getScoreboardColumns, weaponsStatsColumns1, weaponsStatsColumns2 } from "../../lib/columns";
import { DataContext } from "../../lib/context";
import { APIResources } from "../../lib/definitions";
import { calculatePlayerStats, doFetch } from "../../lib/functions";
import { Match } from "../../lib/models";
import { BackgroundBox } from "../backgroundBox";
import { FragHistory } from "../match/fragHistory";
import { Button } from "../general/button";
import { PlacementGraph } from "../match/placementsGraph";
import { Table } from "../table";
import { EloBreakdown } from "../match/eloBreakdown";
import { KillMatrix } from "../match/killMatrix";
import { MetaSegment } from "../match/metaSegment";
import { DamageChart } from "../match/damageChart";

interface MatchOverviewPaneProps {
  latestMatch?: Match;
  updateFavorites(matchId: string, add: boolean): void;
}

export function MatchOverviewPane(props: MatchOverviewPaneProps) {
  const [match, setMatch] = useState<Match>();
  const [isFetchingMatch, setIsFetchingMatch] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { isFetching } = useContext(DataContext);
  const history = useHistory();

  useEffect(() => {
    if (props.latestMatch) {
      setMatch(props.latestMatch);
    } else if (id) {
      setIsFetchingMatch(true);
      doFetch("GET", `${APIResources.Matches}/${props.latestMatch || id}`, setMatch, console.log, () => setIsFetchingMatch(false));
    }
  }, [id, props.latestMatch]);

  const scoreboardStats = useMemo(() => calculatePlayerStats(props.latestMatch || match), [props.latestMatch, match]);

  return (
    <BackgroundBox header={props.latestMatch ? "Latest match" : ""} loading={isFetchingMatch || (isFetching && !match)}>
      {match && (
        <>
          <MetaSegment match={match} latestMatch={props.latestMatch} updateFavorites={props.updateFavorites} />
          <div className="header" style={{ marginTop: "48px" }}>
            Scoreboard
          </div>
          <Table rows={scoreboardStats} columns={getScoreboardColumns(scoreboardStats)} rowKey="nickname" />
          <div className="header" style={{ marginTop: "48px" }}>
            Placements
          </div>
          <PlacementGraph match={match} />
          <div className="header" style={{ marginTop: "48px" }}>
            Damage overview
          </div>
          <DamageChart match={match} />
          <div className="header" style={{ marginTop: "48px" }}>
            Kill Matrix
          </div>
          <div style={{ maxWidth: 1000, overflow: "auto", paddingBottom: 16 }}>
            <KillMatrix playerStats={match.playerstats} fragHistory={match.fraghistory} />
          </div>
          <div className="header " style={{ marginTop: "48px" }}>
            ELO Breakdown
          </div>
          <div style={{ maxWidth: 1000, overflow: "auto", paddingBottom: 16 }}>
            <EloBreakdown ratingsBreakdown={match.ratingsResult.ratingsBreakdown} playerStats={match.playerstats} />
          </div>
          <div className="header" style={{ marginTop: "48px" }}>
            Weapon usage
          </div>
          <Table rows={match.playerstats} rowKey="nickname" columns={weaponsStatsColumns1} noAutoSizeFirstColumn />
          <div style={{ marginTop: "24px" }} />
          <Table rows={match.playerstats} rowKey="nickname" columns={weaponsStatsColumns2} noAutoSizeFirstColumn />
          <div className="header" style={{ marginTop: "48px" }}>
            Kill history
          </div>
          <FragHistory fragHistory={match.fraghistory} playerStats={match.playerstats} />
        </>
      )}
      {!props.latestMatch && (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "48px" }}>
          <Button text="Back" onClick={history.goBack} />
        </div>
      )}
    </BackgroundBox>
  );
}
