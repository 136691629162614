import React from "react";
import { TableColumn } from "../components/table";
import { getColorStylesFromId, getPrettyDuration, getPrettyMapName, getWeaponImageUrl } from "./functions";
import { VscDebugDisconnect } from "react-icons/vsc";
import { BsQuestionCircle } from "react-icons/bs";
import { CgServer } from "react-icons/cg";
import { MapProperties, PlayerGameStats } from "./models";
import { Tooltip } from "../components/general/tooltip";
import { ProgressBar } from "../components/general/progressBar";

export const ratingsColumns: TableColumn[] = [
  {
    title: "Name",
    index: "nickname",
    sort: "string",
    custom: (_v, r) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          ...getColorStylesFromId(r.colorId).css,
        }}
      >
        {r.left && <VscDebugDisconnect style={{ color: "lightgray", marginRight: "8px" }} />}
        {r.nickname}
      </div>
    ),
  },
  {
    title: <div style={{ marginRight: "24px" }}>Master</div>,
    index: "masterRating",
    defaultSortDirection: "Descending",
    align: "center",
  },
  {
    title: <Tooltip tooltip="Rating used for games with 2 players" content="1v1" />,
    index: "ratings.duel",
    custom: (v) => (
      <div style={{ fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>{v}</div>
    ),
  },
  {
    title: "",
    index: "deltas.duel",
    custom: (v) => (
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          color: v > 0 ? "limegreen" : "red",
          marginRight: "24px",
        }}
      >
        <Tooltip
          noIcon
          tooltip="Rating gained/lost today"
          content={
            <div>
              <span style={{ marginRight: -5 }}>{v > 0 ? "+" : ""}</span>
              {v === 0 ? "" : v.toFixed()}
            </div>
          }
        />
      </div>
    ),
  },
  {
    title: <Tooltip tooltip="Rating used for games with 3-4 players" content="FFA" />,
    index: "ratings.ffa",
    custom: (v) => (
      <div style={{ fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>{v}</div>
    ),
  },
  {
    title: "",
    index: "deltas.ffa",
    custom: (v) => (
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          marginRight: "24px",
          color: v > 0 ? "limegreen" : "red",
        }}
      >
        <Tooltip
          noIcon
          tooltip="Rating gained/lost today"
          content={
            <div>
              <span style={{ marginRight: -5 }}>{v > 0 ? "+" : ""}</span>
              {v === 0 ? "" : v.toFixed()}
            </div>
          }
        />
      </div>
    ),
  },
  {
    title: <Tooltip tooltip="Rating used for games with 5+ players" content="Mayhem" />,
    index: "ratings.mayhem",
    custom: (v) => (
      <div style={{ fontSize: "20px", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>{v}</div>
    ),
  },
  {
    title: "",
    index: "deltas.mayhem",
    custom: (v) => (
      <div
        style={{
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          marginRight: "24px",
          color: v > 0 ? "limegreen" : "red",
        }}
      >
        <Tooltip
          noIcon
          tooltip="Rating gained/lost today"
          content={
            <div>
              <span style={{ marginRight: -5 }}>{v > 0 ? "+" : ""}</span>
              {v === 0 ? "" : v.toFixed()}
            </div>
          }
        />
      </div>
    ),
  },
];

export const getScoreboardColumns: (playerGameStats: PlayerGameStats[]) => TableColumn[] = (playerGameStats: PlayerGameStats[]) => {
  const multikillColumnNumbers: Set<number> = new Set();

  playerGameStats.forEach((pgs) => {
    for (let i = 2; i < 11; i++) {
      //@ts-ignore
      if (pgs[`multikills${i}`] > 0) {
        multikillColumnNumbers.add(i);
      }
    }
  });

  const multikillColumns = Array.from(multikillColumnNumbers).map((i) => ({
    title: (
      <div className="tooltip">
        {i}K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of {i === 2 ? "double kills" : i === 3 ? "triple kills" : `${i}K multikills`}</div>
      </div>
    ),
    index: `multikills${i}`,
    custom: (v: any) => v || 0,
  }));

  return [
    {
      title: "Name",
      index: "nickname",
      sort: "string",
      custom: (_v, r) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            ...getColorStylesFromId(r.colorid).css,
          }}
        >
          {r.nickname}
          {r.left && <VscDebugDisconnect style={{ color: "lightgray", marginLeft: "8px" }} />}
          {r.wasHost && <CgServer style={{ color: "lightgray", marginLeft: "8px" }} />}
        </div>
      ),
    },
    {
      title: "ELO",
      index: "oldRating",
      custom: (v) => v.toFixed(0),
    },
    {
      title: "",
      index: "eloDelta",
      custom: (v) => (
        <div
          style={{
            textAlign: "end",
            color: v > 0 ? "limegreen" : v === 0 ? "lightgray" : "red",
            marginLeft: "-8px",
          }}
        >
          <span style={{ marginRight: -5 }}>{v > 0 ? "+" : ""}</span>
          {v.toFixed(0)}
        </div>
      ),
    },
    {
      title: "Score",
      index: "currentscore",
      defaultSortDirection: "Descending",
      sort: (a, b) => a.currentscore - b.currentscore,
    },
    {
      title: "Kills",
      index: "totalkills",
    },
    {
      title: "Deaths",
      index: "totaldeaths",
    },
    {
      title: "K/D",
      index: "",
      sort: (a, b) => a.totalkills / Math.max(a.totaldeaths, 1) - b.totalkills / Math.max(b.totaldeaths, 1),
      custom: (_v, r) => (r.totalkills / Math.max(r.totaldeaths, 1)).toFixed(2),
    },
    {
      title: "Sui",
      index: "suicides",
    },
    {
      title: "Streak",
      index: "longestkillstreak",
    },
    {
      title: (
        <div className="tooltip">
          Dealt/Min
          <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
          <div className="tooltiptext">Damage dealt per minute. Number in '()' is the damage dealt BEFORE dynamic resistance.</div>
        </div>
      ),
      index: "dmgDealtMin",
      custom: (v, r) => v + ` (${r.actualDmgDealtMin})`,
    },
    {
      title: (
        <div className="tooltip">
          Taken/Min
          <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
          <div className="tooltiptext">Damage taken per minute. Number in '()' is the damage taken BEFORE dynamic resistance.</div>
        </div>
      ),
      index: "dmgTakenMin",
      custom: (v, r) => v + ` (${r.actualDmgTakenMin})`,
    },
    ...multikillColumns,
  ];
};

export const playerGeneralStatisticsColumns: TableColumn[] = [
  {
    title: "Name",
    index: "nickname",
    align: "left",
    sort: "string",
    custom: (v, r) => <div style={{ ...getColorStylesFromId(r.color).css, textAlign: "left" }}>{v}</div>,
  },
  {
    title: "Rating",
    index: "rating",
    defaultSortDirection: "Descending",
    custom: (v) => (Number.isNaN(Math.round(v)) ? "N/A" : Math.round(v)),
  },
  {
    title: "Matches",
    index: "matchesPlayed",
  },
  {
    title: "Time played",
    index: "timePlayed",
    custom: (v) => getPrettyDuration(v),
  },
  {
    title: "Kills",
    index: "kills",
  },
  {
    title: "Deaths",
    index: "deaths",
  },
  {
    title: "Suicides",
    index: "suicides",
  },
  {
    title: "K/D",
    index: "",
    sort: (a, b) => a.kills / Math.max(a.deaths, 1) - b.kills / Math.max(b.deaths, 1),
    custom: (_v, r) => (r.kills / Math.max(r.deaths, 1)).toFixed(2),
  },
];

export const playerDmgStatisticsColumns: TableColumn[] = [
  {
    title: "Name",
    index: "nickname",
    align: "left",
    sort: "string",
    custom: (v, r) => <div style={{ ...getColorStylesFromId(r.color).css, textAlign: "left" }}>{v}</div>,
  },
  {
    title: (
      <div className="tooltip">
        Dealt
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Total damage dealt. Number in '()' is the damage dealt BEFORE dynamic resistance.</div>
      </div>
    ),
    index: "dmgDealt",
    defaultSortDirection: "Descending",
    custom: (v, r) => `${(v / 1000).toFixed(0)}K (${(r.actualDmgDealt / 1000).toFixed(0)}K)`,
  },
  {
    title: (
      <div className="tooltip">
        Taken
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Total damage taken. Number in '()' is the damage taken BEFORE dynamic resistance.</div>
      </div>
    ),
    index: "dmgTaken",
    custom: (v, r) => `${(v / 1000).toFixed(0)}K (${(r.actualDmgTaken / 1000).toFixed(0)}K)`,
  },
  {
    title: (
      <div className="tooltip">
        Dealt/Min
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Damage dealt per minute. Number in '()' is the damage dealt per minute BEFORE dynamic resistance.</div>
      </div>
    ),
    index: "dmgDealtMin",
    custom: (v, r) => `${v.toFixed(0)} (${r.actualDmgDealtMin.toFixed(0)})`,
  },
  {
    title: (
      <div className="tooltip">
        Taken/min
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Damage taken per minute. Number in '()' is the damage taken per minute BEFORE dynamic resistance.</div>
      </div>
    ),
    index: "dmgTakenMin",
    custom: (v, r) => `${v.toFixed(0)} (${r.actualDmgTakenMin.toFixed(0)})`,
  },
  {
    title: (
      <div className="tooltip">
        Dealt/taken
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Damge dealt/damage taken ratio. Number in '()' is the ratio BEFORE dynamic resistance.</div>
      </div>
    ),
    index: "1",
    custom: (_, r) => (r.dmgDealt / (r.dmgTaken || 1)).toFixed(2) + ` (${(r.actualDmgDealt / (r.actualDmgTaken || 1)).toFixed(2)})`,
    sort: (a, b) => a.dmgDealt / (a.dmgTaken || 1) - b.dmgDealt / (b.dmgTaken || 1),
  },
];

export const playerFeatsStatisticsColumns: TableColumn[] = [
  {
    title: "Name",
    index: "nickname",
    align: "left",
    sort: "string",
    custom: (v, r) => <div style={{ ...getColorStylesFromId(r.color).css, textAlign: "left" }}>{v}</div>,
  },
  {
    title: (
      <div className="tooltip">
        Streak
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Longest killstreak</div>
      </div>
    ),
    index: "killstreak",
    defaultSortDirection: "Descending",
  },
  {
    title: (
      <div className="tooltip">
        2K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of double kills</div>
      </div>
    ),
    index: "multikills2",
  },
  {
    title: (
      <div className="tooltip">
        3K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of triple kills</div>
      </div>
    ),
    index: "multikills3",
  },
  {
    title: (
      <div className="tooltip">
        4K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of 4K multikills</div>
      </div>
    ),
    index: "multikills4",
  },
  {
    title: (
      <div className="tooltip">
        5K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of 5K multikills</div>
      </div>
    ),
    index: "multikills5",
  },
  {
    title: (
      <div className="tooltip">
        6K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of 6K multikills</div>
      </div>
    ),
    index: "multikills6",
  },
  {
    title: (
      <div className="tooltip">
        7K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of 7K multikills</div>
      </div>
    ),
    index: "multikills7",
  },
  {
    title: (
      <div className="tooltip">
        8K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of 8K multikills</div>
      </div>
    ),
    index: "multikills8",
  },
  {
    title: (
      <div className="tooltip">
        9K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of 9K multikills</div>
      </div>
    ),
    index: "multikills9",
  },
  {
    title: (
      <div className="tooltip">
        10+K
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">Number of 10+K multikills</div>
      </div>
    ),
    index: "multikills0",
  },
];

function getWeaponStatsColumns(weaponIds: number[]) {
  const columns: TableColumn[] = weaponIds.map((n) => ({
    title: (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 8px" }}>
        {n > 100 && <div style={{ margin: 8, color: "lightgray" }}>ALT</div>}
        <img
          src={getWeaponImageUrl(n)}
          alt=""
          style={{
            height: "24px",
            margin: "0 auto 0 auto",
            filter: "brightness(150%)",
          }}
        />
      </div>
    ),
    align: "center",
    index: `weapon${n}kills`,
    custom: (v, row, rows) => {
      const maxPercentage = Math.max(...rows.map((r) => (100 * (r[`weapon${n}kills`] || 0)) / (r.kills || r.totalkills)));
      const percentage = (100 * (v || 0)) / (row.kills || row.totalkills);
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ProgressBar
            percentage={percentage / maxPercentage}
            width={80}
            backgroundColor="rgba(0,0,0,0)"
            text={(percentage || 0).toFixed(0) + "%"}
          />
          <div style={{ marginLeft: "8px", textAlign: "center" }}>{v || 0}</div>
        </div>
      );
    },
    sort: (a, b) => (a[`weapon${n}kills`] || 0) / (a.kills || a.totalkills) - (b[`weapon${n}kills`] || 0) / (b.kills || b.totalkills),
  }));

  columns.unshift({
    title: "Name",
    index: "nickname",
    align: "left",
    sort: "string",
    custom: (v, r) => <div style={{ ...getColorStylesFromId(r.colorid || r.color).css, textAlign: "left" }}>{v}</div>,
  });

  return columns;
}

export const weaponsStatsColumns1: TableColumn[] = getWeaponStatsColumns([1, 2, 3, 103, 4, 5, 105]);
export const weaponsStatsColumns2: TableColumn[] = getWeaponStatsColumns([6, 7, 107, 8, 9, 10, 50]);

const avgPlayers = (row: any) => row.playerCount / row.timesPlayed;
const minutes = (row: any) => row.timePlayed / 60;

export const getMapStatisticsColumns: (mapNameRules: MapProperties[]) => TableColumn[] = (mapNameRules: MapProperties[]) => [
  {
    title: "Name",
    index: "name",
    align: "left",
    sort: "string",
    custom: (v) => getPrettyMapName(v, mapNameRules),
  },
  {
    title: "Popularity",
    index: "popularity",
    defaultSortDirection: "Descending",
    custom: (v, _, rows) => {
      const maxPercentage = Math.max(...rows.map((r) => r.popularity));
      const percentage = v;
      return (
        <ProgressBar
          percentage={percentage / maxPercentage}
          width={120}
          backgroundColor="rgba(0,0,0,0)"
          text={`${(v * 100).toFixed(1)}%`}
        />
      );
    },
  },
  {
    title: "Matches",
    index: "timesPlayed",
  },
  {
    title: "Playtime",
    index: "timePlayed",
    custom: (v) => getPrettyDuration(v),
  },
  {
    title: "Kills",
    index: "kills",
  },
  {
    title: "Dmg dealt",
    index: "dmgDealt",
    custom: (v) => `${(v / 1000).toFixed(1)}K`,
  },
  {
    title: (
      <div className="tooltip">
        Kills/m/p
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">
          Kills/minute/player. A value of 2 would mean that every player on average gets 2 kills every minute
        </div>
      </div>
    ),
    index: "a",
    sort: (a, b) => a.kills / minutes(a) / avgPlayers(a) - b.kills / minutes(b) / avgPlayers(b),
    custom: (_v, r) => (r.kills / minutes(r) / avgPlayers(r)).toFixed(2),
  },
  {
    title: (
      <div className="tooltip">
        Dealt/m/p
        <BsQuestionCircle style={{ marginLeft: "4px", fontSize: "14px" }} />
        <div className="tooltiptext">
          Damage dealt/minute/player. A value of 250 would mean that every player on average deals 250 damage every minute
        </div>
      </div>
    ),
    index: "b",
    sort: (a, b) => a.dmgDealt / minutes(a) / avgPlayers(a) - b.dmgDealt / minutes(b) / avgPlayers(b),
    custom: (_v, r) => (r.dmgDealt / minutes(r) / avgPlayers(r)).toFixed(0),
  },
];
