import React, { useEffect, useState } from "react";
import { BsWindows } from "react-icons/bs";
import { VscTerminalLinux } from "react-icons/vsc";
import { clearCredentials, getUserInfo, login } from "../../lib/credentialsHandler";
import { UserInfo } from "../../lib/models";
import { BackgroundBox } from "../backgroundBox";
import { Button } from "../general/button";

interface LoginPaneProps {
  isLogginIn: boolean;
  onLogin(userInfo: UserInfo): void;
  setRedirectedFromClient(redirected: boolean): void;
}

export function LoginPane(props: LoginPaneProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { onLogin, setRedirectedFromClient } = props;

  useEffect(() => {
    setEmail(new URLSearchParams(window.location.search).get("email") || "");
  }, []);

  useEffect(() => {
    (async () => {
      const refreshToken = new URLSearchParams(window.location.search).get("token") || "";

      if (refreshToken) {
        try {
          await clearCredentials();
          const userInfo = await getUserInfo(refreshToken);
          onLogin(userInfo);
          setRedirectedFromClient(true);
        } catch {}
      }
    })();
  }, [onLogin, setRedirectedFromClient]);

  async function doLogin() {
    setIsWorking(true);
    setErrorMessage("");
    try {
      await login(email, password);
      const userInfo = await getUserInfo();
      props.onLogin(userInfo);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setIsWorking(false);
  }

  return (
    <div style={{ overflow: "auto", height: "100vh" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
          columnGap: "48px",
        }}
      >
        <div />
        <BackgroundBox>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "48px" }}>
            <div style={{ fontSize: "48px", marginBottom: "70px", textAlign: "center" }}>
              Duke Nukem 3D
              <br />
              DM Mod
            </div>
            <div style={{ width: "400px" }}>
              <div style={{ fontSize: "32px", marginBottom: "48px", textAlign: "center" }}>Login</div>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="CKAL email..."
                className="input input-login"
                style={{ fontSize: "20px", marginBottom: "24px" }}
              />
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password..."
                className="input input-login"
                type="password"
                onKeyDown={(e) => e.key === "Enter" && doLogin()}
                style={{ fontSize: "20px", marginBottom: "36px" }}
              />

              <div style={{ display: "flex", justifyContent: "center", marginBottom: "24px" }}>
                <Button text="Login" loading={props.isLogginIn || isWorking} disabled={!email || !password} onClick={doLogin} />
              </div>
              {errorMessage && <div style={{ margin: "0px 24px 24px", textAlign: "center", color: "red" }}>{errorMessage}</div>}
              <div style={{ display: "flex", justifyContent: "space-around", marginTop: errorMessage ? undefined : "60px" }}>
                <a
                  className="link"
                  target="_blank"
                  href={`https://ckal.dk/sign-up/?redirect=${window.location.origin}/login`}
                  rel="noopener noreferrer"
                >
                  Register
                </a>
                <a
                  className="link"
                  target="_blank"
                  href={`https://ckal.dk/forgot-password?redirect=${window.location.origin}/login`}
                  rel="noopener noreferrer"
                >
                  Forgot password?
                </a>
              </div>
              <div style={{ marginTop: "48px", textAlign: "center", fontSize: 24, whiteSpace: "nowrap" }}>
                Download
                <div
                  style={{ display: "flex", columnGap: "36px", justifyContent: "center", color: "lightgray", marginTop: 16, fontSize: 30 }}
                >
                  <BsWindows
                    className="clickable-icon"
                    onClick={() => window.open("https://duke-assets.s3.eu-west-1.amazonaws.com/public/DukeDM.exe", "_newtab")}
                  />
                  <VscTerminalLinux
                    className="clickable-icon"
                    onClick={() => window.open("https://duke-assets.s3.eu-west-1.amazonaws.com/public/DukeDM", "_newtab")}
                  />
                </div>
              </div>
            </div>
          </div>
        </BackgroundBox>
      </div>
    </div>
  );
}
