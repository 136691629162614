import React from "react";
import { BsWindows } from "react-icons/bs";
import { VscTerminalLinux } from "react-icons/vsc";
import { BackgroundBox } from "../backgroundBox";

export function DownloadsPane() {
  return (
    <BackgroundBox header="Downloads">
      <div style={{ display: "flex", justifyContent: "center", margin: "48px 0 24px" }}>
        <div style={{ color: "lightgray", fontSize: 30 }}>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="clickable-icon"
            onClick={() => window.open("https://duke-assets.s3.eu-west-1.amazonaws.com/public/DukeDM.exe", "_newtab")}
          >
            <div style={{ display: "flex", justifyContent: "center", width: 80 }}>
              <BsWindows />
            </div>
            Windows
          </div>
          <div
            style={{ display: "flex", marginTop: 24, alignItems: "center" }}
            className="clickable-icon"
            onClick={() => window.open("https://duke-assets.s3.eu-west-1.amazonaws.com/public/DukeDM", "_newtab")}
          >
            <div style={{ display: "flex", justifyContent: "center", width: 80 }}>
              <VscTerminalLinux />
            </div>
            Linux
          </div>
        </div>
      </div>
    </BackgroundBox>
  );
}
