import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataContext } from "../../lib/context";
import { APIResources } from "../../lib/definitions";
import { doFetch } from "../../lib/functions";
import { Ratings } from "../../lib/models";
import { BackgroundBox } from "../backgroundBox";
import { Checkbox } from "../checkbox";
import { Button } from "../general/button";

export function DonateEloPane() {
  const [selectedMode, setSeletedMode] = useState<keyof Ratings | "">("");
  const [donationAmount, setDonationAmount] = useState(0);
  const [isDonating, setIsDonating] = useState(false);
  const { player, refresh } = useContext(DataContext);
  const history = useHistory();

  useEffect(() => {
    setDonationAmount(0);
  }, [selectedMode]);

  return (
    <BackgroundBox header="Donate ELO">
      <div style={{ color: "lightgray", width: 600 }}>
        <div style={{ marginTop: 64, display: "flex", fontSize: 28, color: "orange", justifyContent: "space-around" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 16 }}>1v1</div>
            <Checkbox checked={selectedMode === "duel"} onClick={() => setSeletedMode("duel")} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 16 }}>FFA</div>
            <Checkbox checked={selectedMode === "ffa"} onClick={() => setSeletedMode("ffa")} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 16 }}>Mayhem</div>
            <Checkbox checked={selectedMode === "mayhem"} onClick={() => setSeletedMode("mayhem")} />
          </div>
        </div>
        {selectedMode && (
          <div
            style={{
              marginTop: 32,
              fontSize: 24,
              display: "grid",
              gridTemplateColumns: "min-content auto min-content",
              rowGap: 16,
              columnGap: 24,
            }}
          >
            <div style={{ whiteSpace: "nowrap" }}>Current rating:</div>
            <div />
            <div style={{ textAlign: "end" }}>{player.ratings[selectedMode]}</div>
            <div>Donation:</div>
            <input
              type="range"
              min={0}
              max={player.ratings[selectedMode] - 800}
              step="1"
              value={donationAmount}
              onChange={(e) => setDonationAmount(Number.parseInt(e.target.value))}
              style={{ width: "100%" }}
            />
            <div style={{ marginLeft: "24px", fontSize: "24px", textAlign: "end" }}>{donationAmount}</div>
            <div style={{ whiteSpace: "nowrap" }}>New rating:</div>
            <div />
            <div style={{ textAlign: "end" }}> {player.ratings[selectedMode] - donationAmount}</div>
          </div>
        )}
      </div>
      {selectedMode && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            text="Donate"
            disabled={donationAmount === 0}
            loading={isDonating}
            onClick={() => {
              setIsDonating(true);
              doFetch(
                "POST",
                APIResources.MeDonateELO,
                () => {
                  history.push("/me");
                  refresh();
                },
                console.log,
                () => setIsDonating(false),
                { gameMode: selectedMode, donationAmount: donationAmount }
              );
            }}
            style={{ marginTop: 48 }}
          />
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button text="Back" small onClick={() => history.push("/me")} style={{ marginTop: "92px" }} />
      </div>
    </BackgroundBox>
  );
}
