import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { DataContext } from "../../lib/context";
import { IMAGE_S3_URL, killLimits } from "../../lib/definitions";
import { calculateKillCount, getColorStylesFromId, getPrettyDuration } from "../../lib/functions";
import { PlayerStatistics, Ratings } from "../../lib/models";
import { BackgroundBox } from "../backgroundBox";
import { Button } from "../general/button";
import { ProgressBar } from "../general/progressBar";
import { Tooltip } from "../general/tooltip";
import { WeaponUsageChart } from "../weaponsUsageChart";
import { MatchHistoryTable } from "./matchHistoryTable";
import { SimpleRatingChart } from "./simpleRatingChart";

interface MyPagePaneProps {
  onLogout(): void;
}

export function MyPagePane(props: MyPagePaneProps) {
  const { isFetching, player, playerStats, matches } = useContext(DataContext);

  const history = useHistory();

  const myStats: PlayerStatistics | undefined = playerStats.find((p) => p.nickname === player?.nickname);

  const killCount = useMemo(() => calculateKillCount(matches, player.nickname), [matches, player]);

  const remainingCount = useMemo(() => {
    const remaining = { ...killLimits };
    Object.entries(killCount).forEach(([ratingType, kills]) => (remaining[ratingType as keyof Ratings] -= kills));
    return remaining;
  }, [killCount]);

  return (
    <BackgroundBox header={<div style={{ ...getColorStylesFromId(player?.colorId).css }}>{player?.nickname}</div>} loading={isFetching}>
      <div style={{ display: "grid", gridTemplateColumns: "auto min-content", columnGap: "48px" }}>
        <div style={{ fontSize: "20px" }}>
          {myStats && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto min-content",
                columnGap: "24px",
                marginBottom: "24px",
                rowGap: "4px",
                fontSize: "24px",
              }}
            >
              <div style={{ whiteSpace: "nowrap" }}>Time played:</div>
              <div style={{ color: "lightgray", textAlign: "end" }}>{getPrettyDuration(myStats.timePlayed)}</div>
              <div>K/D:</div>
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>{`${myStats.kills}/${myStats.deaths} (${(
                myStats.kills / (myStats.deaths || 1)
              ).toFixed(2)})`}</div>
            </div>
          )}
          <div style={{ fontSize: "24px", margin: "36px 0 12px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>Ratings</div>
            <Button text="Donate ELO" small onClick={() => history.push("/me/donate-elo")} />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "auto min-content", columnGap: "24px", rowGap: "8px" }}>
            <Tooltip
              content="Master:"
              tooltip="Your Master rating is an average of your other ratings. However, if you have few kills in the game modes (1v1, FFA & Mayhem) the Master rating will be reduced."
            />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <div style={{ color: "lightgray", marginRight: "32px" }}>{player?.masterRating}</div>
              <ProgressBar
                percentage={
                  Object.entries(killCount).reduce(
                    (prev, [ratingType, kills]) => prev + Math.min(killLimits[ratingType as keyof Ratings], kills),
                    0
                  ) / Object.values(killLimits).reduce((prev, curr) => prev + curr, 0)
                }
                width={250}
              />
            </div>
            <div />
            <div style={{ gridColumn: "span 2", borderBottom: "1px solid gray" }} />
            {Object.entries(player.ratings).map(([ratingType, rating]) => (
              <React.Fragment key={ratingType}>
                <Tooltip
                  content={(ratingType === "duel" ? "1v1" : ratingType === "mayhem" ? "Mayhem" : "FFA") + ":"}
                  tooltip={
                    (ratingType === "duel"
                      ? "Rating used for 1v1 matches. "
                      : ratingType === "ffa"
                      ? "Rating used for matches with 3-4 players. "
                      : "Rating used for matches with 5 or more players. ") +
                    (remainingCount[ratingType as keyof Ratings] >= 0
                      ? `You currently need ${remainingCount[ratingType as keyof Ratings]} more kill${
                          remainingCount[ratingType as keyof Ratings] === 1 ? "" : "s"
                        } in this game mode to get this rating to count 100% in your Master rating.`
                      : "")
                  }
                />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                  <div style={{ color: "lightgray", marginRight: "32px" }}>{rating}</div>
                  <div style={{ position: "relative" }}>
                    <ProgressBar
                      percentage={killCount[ratingType as keyof Ratings] / killLimits[ratingType as keyof Ratings]}
                      width={250}
                    />
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "14px",
                        top: -1,
                        zIndex: 10,
                        left: "50%",
                        transform: "translate(-50%)",
                      }}
                    >
                      {`${killCount[ratingType as keyof Ratings]}/${killLimits[ratingType as keyof Ratings]}`}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          <SimpleRatingChart />
        </div>

        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={`${IMAGE_S3_URL}duke-skins/${player?.colorId || 0}.png`} style={{ width: "128px" }} alt="" />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button text="Change skin" style={{ marginTop: "24px" }} small onClick={() => history.push("/me/change-skin")} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: 48, marginBottom: 72 }}>
        <MatchHistoryTable isPersonalMatchHistory />
      </div>
      <div style={{ fontSize: "24px", margin: "24px 0 8px" }}>Statistics</div>
      <div style={{ marginBottom: "72px" }}>
        <WeaponUsageChart />
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "min-content min-content",
            columnGap: "24px",
            fontSize: "16px",
            rowGap: "4px",
            whiteSpace: "nowrap",
            marginRight: "36px",
          }}
        >
          {myStats && (
            <>
              <div>Games played:</div>
              <div style={{ color: "lightgray", textAlign: "end" }}>{myStats.matchesPlayed}</div>

              <div>Time played:</div>
              <div style={{ color: "lightgray", textAlign: "end" }}>{getPrettyDuration(myStats.timePlayed)}</div>

              <div style={{ gridColumn: "span 2", height: "8px" }} />

              <div>Kills:</div>
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>{myStats.kills}</div>

              <div>Deaths:</div>
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>{myStats.deaths}</div>

              <div>K/D:</div>
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>
                {(myStats.kills / (myStats.deaths || 1)).toFixed(2)}
              </div>

              <div>Suicides:</div>
              <div style={{ color: "lightgray", textAlign: "end" }}>{myStats.suicides}</div>
            </>
          )}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "min-content min-content",
            columnGap: "24px",
            fontSize: "16px",
            rowGap: "4px",
            whiteSpace: "nowrap",
          }}
        >
          {myStats && (
            <>
              <Tooltip content="Dmg dealt:" tooltip="Total damage dealt. Number in '()' is the damage dealt BEFORE dynamic resistance." />
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>
                {`${(myStats.dmgDealt / 1000).toFixed(1)}K (${(myStats.actualDmgDealt / 1000).toFixed(1)}K)`}
              </div>

              <Tooltip content="Dmg taken:" tooltip="Total damage taken. Number in '()' is the damage taken BEFORE dynamic resistance." />
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>
                {`${(myStats.dmgTaken / 1000).toFixed(1)}K (${(myStats.actualDmgTaken / 1000).toFixed(1)}K)`}
                <div style={{ gridColumn: "span 2" }} />
              </div>

              <Tooltip
                content="Dmg dealt/min:"
                tooltip="Damage dealt per minute. Number in '()' is the damage dealt per minute BEFORE dynamic resistance."
              />
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>
                {myStats.dmgDealtMin.toFixed(0) + ` (${myStats.actualDmgDealtMin.toFixed(0)})`}
              </div>

              <Tooltip
                content="Dmg taken/min:"
                tooltip="Damage taken per minute. Number in '()' is the damage taken per minute BEFORE dynamic resistance."
              />
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>
                {myStats.dmgTakenMin.toFixed(0) + ` (${myStats.actualDmgTakenMin.toFixed()})`}
              </div>

              <Tooltip
                content="Dealt/taken:"
                tooltip="Damge dealt/damage taken ratio. Number in '()' is the ratio BEFORE dynamic resistance"
              />
              <div style={{ color: "lightgray", textAlign: "end", whiteSpace: "nowrap" }}>
                {(myStats.dmgDealt / (myStats.dmgTaken || 1)).toFixed(2) +
                  ` (${(myStats.actualDmgDealt / (myStats.actualDmgTaken || 1)).toFixed(2)})`}
              </div>

              <div style={{ gridColumn: "span 2", height: "8px" }} />

              <div style={{ whiteSpace: "nowrap" }}>Longest killstreak:</div>
              <div style={{ color: "lightgray", textAlign: "end" }}>{myStats.killstreak}</div>

              {[2, 3, 4, 5, 6, 7, 8, 9, 0].map((n) =>
                // @ts-ignore
                myStats[`multikills${n}`] ? (
                  <React.Fragment key={n}>
                    {
                      <>
                        <div style={{ whiteSpace: "nowrap" }}>
                          {n === 2 ? "Double kills:" : n === 3 ? "Triple kills:" : `Multikills (${n}):`}
                        </div>
                        {/* @ts-ignore */}
                        <div style={{ color: "lightgray", textAlign: "end" }}>{myStats[`multikills${n}`]}</div>
                      </>
                    }
                  </React.Fragment>
                ) : null
              )}
            </>
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", margin: "128px 24px 0 24px" }}>
        <Button text="Log out" onClick={props.onLogout} small />
      </div>
    </BackgroundBox>
  );
}
