import React, { useContext, useMemo } from "react";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, Legend } from "recharts";
import { DataContext } from "../lib/context";
import { calculateEloData, getColorStylesFromId, getPrettyMapName } from "../lib/functions";
import { Match } from "../lib/models";

interface EloGraphProps {
  matches: Match[];
}

export function EloGraph(props: EloGraphProps) {
  const { player, players, mapProperties } = useContext(DataContext);

  const eloData = useMemo(() => calculateEloData(props.matches), [props.matches]);

  return (
    <div style={{ width: "1000px" }}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          data={eloData}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
          <XAxis dataKey="x" domain={[0, props.matches.length]} type="number" />
          <YAxis domain={["dataMin - 10", "dataMax + 10"]} tickFormatter={(t) => Number.parseInt(t) + ""} />
          {players.map((p) => (
            <Line
              key={p.nickname}
              name={p.nickname}
              stroke={getColorStylesFromId(p.colorId).primary}
              dataKey={p.nickname}
              x="date"
              dot={false}
              connectNulls
              strokeWidth={p.nickname === player?.nickname ? 3 : 1}
            />
          ))}
          <Tooltip
            formatter={(v: number) => v.toFixed(0)}
            labelFormatter={(l) =>
              "Match " + (l + 1) + `: ${getPrettyMapName(props.matches[props.matches.length - (l + 1)].map, mapProperties)}`
            }
          />
          <Legend />,
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
