import React, { useContext, useEffect, useMemo, useState } from "react";
import { DataContext } from "../../lib/context";
import { BackgroundBox } from "../backgroundBox";
import { APIResources, IMAGE_S3_URL } from "../../lib/definitions";
import { doFetch } from "../../lib/functions";
import { MapProperties } from "../../lib/models";
import { Button } from "../general/button";

export function MapsPane() {
  const [maps, setMaps] = useState<string[]>([]);
  const [inspectingMap, setInspectingMap] = useState("");

  const { mapProperties } = useContext(DataContext);

  const sortedMaps = useMemo(
    () =>
      [...maps].sort((a, b) => {
        const mpA: MapProperties = mapProperties.find((mp) => new RegExp(mp.test).test(a))!;
        const mpB: MapProperties = mapProperties.find((mp) => new RegExp(mp.test).test(b))!;

        if (mpA.maxPlayers === mpB.maxPlayers) {
          return mpA.prettyName.localeCompare(mpB.prettyName);
        }

        return mpA.maxPlayers - mpB.maxPlayers;
      }),
    [maps, mapProperties]
  );

  useEffect(() => {
    doFetch("GET", APIResources.Maps, setMaps, console.log);
  }, []);

  return (
    <div style={{ display: "relative" }}>
      <BackgroundBox>
        {sortedMaps.map((m, i) => {
          const mp: MapProperties | undefined = mapProperties.find((mp) => new RegExp(mp.test).test(m));
          return mp ? (
            <div key={mp._id} style={{ marginBottom: i !== mapProperties.length - 1 ? 48 : undefined }}>
              <div style={{ display: "flex", border: "1px solid orange", borderRadius: 12 }}>
                <img
                  src={IMAGE_S3_URL + "thumbnails/" + mp.imageUrl}
                  alt=""
                  height={256}
                  style={{ borderTopLeftRadius: 12, borderBottomLeftRadius: 12, borderRight: "1px solid orange" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "12px 24px 24px 24px",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "grid", rowGap: 8, fontSize: 20, height: "min-content", color: "lightgray" }}>
                    <div style={{ fontSize: 30, marginBottom: 16, color: "orange" }}>{mp.prettyName}</div>
                    <div>{"Version: " + m.split("-")[1].slice(0, -4)}</div>
                    <div>{"Max players: " + mp.maxPlayers}</div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button text="Inspect" onClick={() => setInspectingMap(m)} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>No information about {m}</div>
          );
        })}
      </BackgroundBox>
      {inspectingMap && (
        <>
          <div
            style={{ position: "absolute", width: "100vw", height: "100vh", background: "rgba(0,0,0,0.8)", left: 0, top: 0 }}
            onClick={() => setInspectingMap("")}
          />
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              height: "80vh",
              width: "60vw",
              transform: "translate(-50%,-50%)",
            }}
          >
            <iframe
              title={inspectingMap}
              src={`https://duke-map-viewer.ckal.dk?map=${inspectingMap}`}
              width="100%"
              height="100%"
              style={{ border: "none" }}
            >
              <p>Your browser does not support iframes.</p>
            </iframe>
          </div>
        </>
      )}
    </div>
  );
}
