import * as React from "react";
import { FONT_SIZE_VERYLARGE } from "../lib/definitions";
import { Spinner } from "./spinner";

interface BackgroundBoxProps {
  children?: React.ReactNode;
  header?: React.ReactNode;
  loading?: boolean;
  style?: React.CSSProperties;
}

export function BackgroundBox(props: BackgroundBoxProps) {
  return (
    <div
      style={{
        minWidth: "600px",
        background: "rgba(0,0,0,0.7)",
        border: "2px solid black",
        borderRadius: "32px",
        padding: "16px 24px 36px 24px",
        height: "min-content",
        margin: "48px 0",
        ...props.style,
      }}
    >
      {props.loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner />
        </div>
      ) : (
        <>
          {props.header && (
            <div
              style={{ fontSize: FONT_SIZE_VERYLARGE, color: "orange", textAlign: "center", marginBottom: "24px" }}
              className="big-font-border"
            >
              {props.header}
            </div>
          )}
          {props.children}
        </>
      )}
    </div>
  );
}
