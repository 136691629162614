import React from "react";

interface CheckboxProps {
  checked: boolean;
  onClick(): void;
}

export function Checkbox(props: CheckboxProps) {
  return (
    <div
      style={{ height: "14px", width: "14px", padding: "5px", cursor: "pointer", border: "1px solid gray", borderRadius: "8px" }}
      onClick={props.onClick}
    >
      {props.checked && <div style={{ borderRadius: "4px", backgroundColor: "orange", width: "100%", height: "100%" }} />}
    </div>
  );
}
