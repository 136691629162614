import { useLocation } from "react-router";
import { CredentialsError, isAccessTokenOld, refreshCredentials } from "./credentialsHandler";
import { BACKEND_URL, IMAGE_S3_URL, LF_CREDENTIALS } from "./definitions";
import { Credentials, EloType, MapProperties, Match, Player, PlayerStatistics, Ratings } from "./models";
import * as localForage from "localforage";
import { CSSProperties } from "react";

export async function doFetch(
  httpMethod: "GET" | "POST" | "PUT" | "DELETE",
  path: string,
  onOK: (json: any) => void,
  onNotOK: (json: any) => void,
  finallyCallback?: () => void,
  body?: any
) {
  try {
    const response = await fetch(`${BACKEND_URL}${path}`, {
      headers: { "Content-Type": "application/json", Authorization: await getAccessToken() },
      method: httpMethod,
      body: body ? JSON.stringify(body) : undefined,
    });
    if (response.ok) {
      try {
        onOK(await response.json());
      } catch {
        onOK(`${response.status} ${response.statusText}`);
      }
    } else {
      try {
        onNotOK(await response.json());
      } catch (error) {
        onNotOK(response.statusText);
      }
    }
  } catch (error) {
    console.log(error);
    onNotOK("An error occured");
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
}

export async function getAccessToken() {
  let credentials: Credentials | null;

  try {
    credentials = await localForage.getItem(LF_CREDENTIALS);
  } catch {
    throw new Error("An unexpected error occurred");
  }

  if (!credentials) {
    throw new CredentialsError();
  }

  if (isAccessTokenOld(credentials)) {
    try {
      credentials = await refreshCredentials(credentials.RefreshToken!);
      await localForage.setItem(LF_CREDENTIALS, credentials);
    } catch (error) {
      console.log(error);
      throw new CredentialsError();
    }
  }

  return credentials.AccessToken + "";
}

export function getRatingsTimestamp(timestamp: number | undefined) {
  if (!timestamp) {
    return "N/A";
  }

  const date = new Date(timestamp);

  return `${date.toDateString().slice(0, -4)} ${date.toLocaleTimeString().slice(0, -3).replace(".", ":")}`;
}

export function getMatchTimestamp(timestamp: number | undefined) {
  if (!timestamp) {
    return "N/A";
  }

  const date = new Date(timestamp);
  return `${date.toLocaleDateString().slice(0, -5).replace(".", "/")} ${date.toLocaleTimeString().slice(0, -3).replace(".", ":")}`;
}

export function getPatchTimestamp(timestamp: number) {
  const date = new Date(timestamp);

  return `${date.toDateString().slice(0, -4)} ${date.getFullYear()}`;
}

export function getPrettyDuration(secs: number | undefined, isTicks?: boolean) {
  if (secs === undefined) {
    return "N/A";
  }

  secs = isTicks ? secs / 30 : secs;

  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs % 3600) / 60);
  const seconds = Math.floor(secs % 60);
  const hundredths = secs.toFixed(2).slice(-2);

  return `${hours ? hours + ":" : ""}${hours ? minutes.toString().padStart(2, "0") : minutes}:${
    seconds.toString().padStart(2, "0") + (isTicks ? `.${hundredths}` : "")
  }`;
}

export function calculateWinner(match: Match | undefined) {
  return match?.playerstats.find((p) => p.currentscore === match.fraglimit);
}

export function calculatePosition(match: Match, nickname: string) {
  const player = match.playerstats.find((p) => p.nickname === nickname);

  if (!player) {
    return "N/A";
  }

  let position = 1;

  match.playerstats.forEach((p) => {
    if (p.nickname !== nickname && p.currentscore >= player.currentscore) {
      position++;
    }
  });

  return position;
}

export function getWeaponImageUrl(weapon: number) {
  let path = "";
  if (weapon === 1) {
    path = "kick.png";
  } else if (weapon === 2) {
    path = "pistol.png";
  } else if (weapon === 3) {
    path = "shotgun.png";
  } else if (weapon === 103) {
    path = "shotgun.png";
  } else if (weapon === 4) {
    path = "chaingun.png";
  } else if (weapon === 104) {
    path = "chaingun.png";
  } else if (weapon === 5) {
    path = "rpg.png";
  } else if (weapon === 105) {
    path = "rpg.png";
  } else if (weapon === 6) {
    path = "pipebomb.png";
  } else if (weapon === 7) {
    path = "shrinker.png";
  } else if (weapon === 107) {
    path = "shrinker.png";
  } else if (weapon === 8) {
    path = "devastator.png";
  } else if (weapon === 9) {
    path = "tripbomb.png";
  } else if (weapon === 10) {
    path = "freezer.png";
  } else if (weapon === 50) {
    path = "flashbang.png";
  }

  return IMAGE_S3_URL + "items/" + path;
}

export function calculatePlayerStats(match?: Match) {
  if (!match) {
    return [];
  }

  const minutes = match.gametime / 60;

  return match.playerstats.map((ps) => {
    const ratingResult = match.ratingsResult.playerRatingResults.find((r) => r.nickname === ps.nickname);

    return {
      ...ps,
      placement: match.playerstats.reduce(
        (prev, curr) => (curr.currentscore < ps.currentscore ? prev - 1 : prev),
        match.playerstats.length
      ),
      dmgDealtMin: Math.round(ps.damagedonetotal / minutes),
      actualDmgDealtMin: Math.round(ps.actualdamagedonetotal / minutes),
      dmgTakenMin: Math.round(ps.damagetakentotal / minutes),
      actualDmgTakenMin: Math.round(ps.actualdamagetakentotal / minutes),
      eloDelta: ratingResult?.delta,
      oldRating: ratingResult?.oldRating,
      wasHost: match.hostNickname === ps.nickname,
    };
  });
}

export function calculatePlacementsData(match: Match) {
  const killsData: any[] = [{ gametimecounter: 0 }];

  match.playerstats.forEach((p) => (killsData[0][p.nickname] = 0));

  const currentScores: { [k in string]: number } = {};

  match.playerstats.forEach((p) => {
    currentScores[p.nickname] = 0;
  });

  match.fraghistory.sort((a, b) => a.gametimecounter - b.gametimecounter);

  let minScore = 0;

  match.fraghistory.forEach((frag) => {
    currentScores[frag.killedby] = currentScores[frag.killedby] + (frag.nickname === frag.killedby ? -2 : 1);

    const dataPoint: any = { gametimecounter: frag.gametimecounter };

    for (const playerName in currentScores) {
      dataPoint[playerName] = currentScores[playerName];

      if (currentScores[playerName] < minScore) {
        minScore = currentScores[playerName];
      }
    }

    killsData.push(dataPoint);
  });

  const placementData: any[] = [];

  JSON.parse(JSON.stringify(killsData)).forEach((d: any) => {
    const dataPoint: any = { gametimecounter: d.gametimecounter };
    delete d.gametimecounter;
    for (const playerName in d) {
      let placement = match.playerstats.length;
      for (const innerPlayerName in d) {
        if (playerName !== innerPlayerName) {
          if (d[playerName] > d[innerPlayerName]) {
            placement--;
          }
        }
      }
      dataPoint[playerName] = placement;
    }
    placementData.push(dataPoint);
  });

  return { placementData: placementData, killsData: killsData, minScore: minScore };
}

export function getPlacementXTicks(noPlayers: number) {
  const ticks = [];

  for (let i = 0; i < noPlayers; i++) {
    ticks.push(i + 1);
  }

  return ticks;
}

export function getFragPercentageXTicks(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const ticks = [];

  for (let i = 0; i <= minutes; i++) {
    ticks.push(i * 60 * 30);
  }

  return ticks;
}

export function calculateStats(matches: Match[], players: Player[]): PlayerStatistics[] {
  const base: { [nickname in string]: number } = {};
  players.forEach((p) => (base[p.nickname] = 0));

  const matchesPlayed: { [k in string]: number } = { ...base };
  const timePlayed: { [k in string]: number } = { ...base };
  const kills: { [k in string]: number } = { ...base };
  const deaths: { [k in string]: number } = { ...base };
  const suicides: { [k in string]: number } = { ...base };
  const dmgDealt: { [k in string]: number } = { ...base };
  const dmgTaken: { [k in string]: number } = { ...base };
  const actualDmgDealt: { [k in string]: number } = { ...base };
  const actualDmgTaken: { [k in string]: number } = { ...base };
  const killstreak: { [k in string]: number } = { ...base };
  const multikills2: { [k in string]: number } = { ...base };
  const multikills3: { [k in string]: number } = { ...base };
  const multikills4: { [k in string]: number } = { ...base };
  const multikills5: { [k in string]: number } = { ...base };
  const multikills6: { [k in string]: number } = { ...base };
  const multikills7: { [k in string]: number } = { ...base };
  const multikills8: { [k in string]: number } = { ...base };
  const multikills9: { [k in string]: number } = { ...base };
  const multikills0: { [k in string]: number } = { ...base };
  const weapon1kills: { [k in string]: number } = { ...base };
  const weapon2kills: { [k in string]: number } = { ...base };
  const weapon3kills: { [k in string]: number } = { ...base };
  const weapon4kills: { [k in string]: number } = { ...base };
  const weapon5kills: { [k in string]: number } = { ...base };
  const weapon6kills: { [k in string]: number } = { ...base };
  const weapon7kills: { [k in string]: number } = { ...base };
  const weapon8kills: { [k in string]: number } = { ...base };
  const weapon9kills: { [k in string]: number } = { ...base };
  const weapon10kills: { [k in string]: number } = { ...base };
  const weapon50kills: { [k in string]: number } = { ...base };
  const weapon101kills: { [k in string]: number } = { ...base };
  const weapon102kills: { [k in string]: number } = { ...base };
  const weapon103kills: { [k in string]: number } = { ...base };
  const weapon104kills: { [k in string]: number } = { ...base };
  const weapon105kills: { [k in string]: number } = { ...base };
  const weapon106kills: { [k in string]: number } = { ...base };
  const weapon107kills: { [k in string]: number } = { ...base };
  const weapon108kills: { [k in string]: number } = { ...base };
  const weapon109kills: { [k in string]: number } = { ...base };
  const weapon110kills: { [k in string]: number } = { ...base };

  matches.forEach((m) => {
    m.playerstats.forEach((p) => {
      matchesPlayed[p.nickname]++;
      timePlayed[p.nickname] += m.gametime;
      kills[p.nickname] += p.totalkills;
      deaths[p.nickname] += +p.totaldeaths;
      suicides[p.nickname] += p.suicides;
      dmgDealt[p.nickname] += p.damagedonetotal;
      dmgTaken[p.nickname] += p.damagetakentotal;
      actualDmgDealt[p.nickname] += p.actualdamagedonetotal;
      actualDmgTaken[p.nickname] += p.actualdamagetakentotal;
      killstreak[p.nickname] = Math.max(killstreak[p.nickname], p.longestkillstreak);
      multikills2[p.nickname] += p.multikills2 || 0;
      multikills3[p.nickname] += p.multikills3 || 0;
      multikills4[p.nickname] += p.multikills4 || 0;
      multikills5[p.nickname] += p.multikills5 || 0;
      multikills6[p.nickname] += p.multikills6 || 0;
      multikills7[p.nickname] += p.multikills7 || 0;
      multikills8[p.nickname] += p.multikills8 || 0;
      multikills9[p.nickname] += p.multikills9 || 0;
      multikills0[p.nickname] += p.multikills0 || 0;
      weapon1kills[p.nickname] += p.weapon1kills || 0;
      weapon2kills[p.nickname] += p.weapon2kills || 0;
      weapon3kills[p.nickname] += p.weapon3kills || 0;
      weapon4kills[p.nickname] += p.weapon4kills || 0;
      weapon5kills[p.nickname] += p.weapon5kills || 0;
      weapon6kills[p.nickname] += p.weapon6kills || 0;
      weapon7kills[p.nickname] += p.weapon7kills || 0;
      weapon8kills[p.nickname] += p.weapon8kills || 0;
      weapon9kills[p.nickname] += p.weapon9kills || 0;
      weapon10kills[p.nickname] += p.weapon10kills || 0;
      weapon50kills[p.nickname] += p.weapon50kills || 0;
      weapon101kills[p.nickname] += p.weapon101kills || 0;
      weapon102kills[p.nickname] += p.weapon102kills || 0;
      weapon103kills[p.nickname] += p.weapon103kills || 0;
      weapon104kills[p.nickname] += p.weapon104kills || 0;
      weapon105kills[p.nickname] += p.weapon105kills || 0;
      weapon106kills[p.nickname] += p.weapon106kills || 0;
      weapon107kills[p.nickname] += p.weapon107kills || 0;
      weapon108kills[p.nickname] += p.weapon108kills || 0;
      weapon109kills[p.nickname] += p.weapon109kills || 0;
      weapon110kills[p.nickname] += p.weapon110kills || 0;
    });
  });

  const stats: PlayerStatistics[] = [];

  for (const nickname in matchesPlayed) {
    stats.push({
      nickname: nickname,
      color: players.find((p) => p.nickname === nickname)?.colorId || 0,
      matchesPlayed: matchesPlayed[nickname],
      timePlayed: timePlayed[nickname],
      kills: kills[nickname],
      deaths: deaths[nickname],
      suicides: suicides[nickname],
      dmgDealt: dmgDealt[nickname],
      dmgTaken: dmgTaken[nickname],
      dmgDealtMin: (dmgDealt[nickname] * 60) / (timePlayed[nickname] || 1),
      dmgTakenMin: (dmgTaken[nickname] * 60) / (timePlayed[nickname] || 1),
      actualDmgDealt: actualDmgDealt[nickname],
      actualDmgTaken: actualDmgTaken[nickname],
      actualDmgDealtMin: (actualDmgDealt[nickname] * 60) / (timePlayed[nickname] || 1),
      actualDmgTakenMin: (actualDmgTaken[nickname] * 60) / (timePlayed[nickname] || 1),
      killstreak: killstreak[nickname],
      multikills2: multikills2[nickname],
      multikills3: multikills3[nickname],
      multikills4: multikills4[nickname],
      multikills5: multikills5[nickname],
      multikills6: multikills6[nickname],
      multikills7: multikills7[nickname],
      multikills8: multikills8[nickname],
      multikills9: multikills9[nickname],
      multikills0: multikills0[nickname],
      weapon1kills: weapon1kills[nickname],
      weapon2kills: weapon2kills[nickname],
      weapon3kills: weapon3kills[nickname],
      weapon4kills: weapon4kills[nickname],
      weapon5kills: weapon5kills[nickname],
      weapon6kills: weapon6kills[nickname],
      weapon7kills: weapon7kills[nickname],
      weapon8kills: weapon8kills[nickname],
      weapon9kills: weapon9kills[nickname],
      weapon10kills: weapon10kills[nickname],
      weapon50kills: weapon50kills[nickname],
      weapon101kills: weapon101kills[nickname],
      weapon102kills: weapon102kills[nickname],
      weapon103kills: weapon103kills[nickname],
      weapon104kills: weapon104kills[nickname],
      weapon105kills: weapon105kills[nickname],
      weapon106kills: weapon106kills[nickname],
      weapon107kills: weapon107kills[nickname],
      weapon108kills: weapon108kills[nickname],
      weapon109kills: weapon109kills[nickname],
      weapon110kills: weapon110kills[nickname],
    });
  }

  return stats.sort((a, b) => b.kills - a.kills);
}

export function calculateEloData(matches: Match[]) {
  const eloData: { [k in string]: number }[] = [];

  [...matches]
    .sort((a, b) => a.timestamp - b.timestamp)
    .forEach((m, i) => {
      const point: { [k in string]: number } = { x: i };
      m.ratingsResult.playerRatingResults.forEach((r) => {
        const average = Object.values(r.newRatings).reduce((prev, curr) => prev + curr, 0) / Object.values(r.newRatings).length;
        point[r.nickname] = average;
        eloData.push(point);
      });
    });

  return eloData;
}

export function calculateMyRatingsData(matches: Match[], player: Player | undefined) {
  if (!player) {
    return [];
  }

  const data = matches
    .filter((m) => m.playerstats.find((p) => p.nickname === player.nickname))
    .sort((a, b) => a.timestamp - b.timestamp)
    .map((m) => {
      const newRatings: Ratings = m.ratingsResult.playerRatingResults.find((p) => p.nickname === player.nickname)?.newRatings || {
        ffa: 0,
        duel: 0,
        mayhem: 0,
      };
      return { master: Object.values(newRatings).reduce((prev, curr) => prev + curr, 0) / Object.values(newRatings).length, ...newRatings };
    });

  return data;
}

export function calculateOverallStats(matches: Match[], players: Player[]) {
  const stats: { noMatches: number; timePlayed: number; kills: number; suicides: number; dmgDealt: number } = {
    noMatches: matches.length,
    timePlayed: 0,
    kills: 0,
    suicides: 0,
    dmgDealt: 0,
  };

  const playerStats = calculateStats(matches, players);

  playerStats.forEach((ps) => {
    stats.timePlayed += ps.timePlayed;
    stats.kills += ps.kills;
    stats.suicides += ps.suicides;
    stats.dmgDealt += ps.dmgDealt;
  });

  return stats;
}

interface MapStats {
  timesPlayed: number;
  timePlayed: number;
  kills: number;
  suicides: number;
  dmgDealt: number;
  playerCount: number;
}

export function getPrettyMapName(mapName: string, mapNameRules: MapProperties[]) {
  return mapNameRules.find((rule) => new RegExp(rule.test).test(mapName))?.prettyName || mapName.replace(".map", "");
}

export function calculateMapStats(matches: Match[], mapNameRules: MapProperties[]) {
  const mapStats: {
    [k in string]: MapStats;
  } = {};

  matches.forEach((m) => {
    const mapName = getPrettyMapName(m.map, mapNameRules);
    const kills = m.playerstats.reduce((prev, curr) => prev + curr.totalkills, 0);
    const suicides = m.playerstats.reduce((prev, curr) => prev + curr.suicides, 0);
    const dmgDealt = m.playerstats.reduce((prev, curr) => prev + curr.damagedonetotal, 0);
    if (!mapStats[mapName]) {
      mapStats[mapName] = {
        timesPlayed: 1,
        timePlayed: m.gametime,
        kills: kills,
        suicides: suicides,
        dmgDealt: dmgDealt,
        playerCount: m.playerstats.length,
      };
    } else {
      mapStats[mapName].timesPlayed += 1;
      mapStats[mapName].timePlayed += m.gametime;
      mapStats[mapName].kills += kills;
      mapStats[mapName].suicides += suicides;
      mapStats[mapName].dmgDealt += dmgDealt;
      mapStats[mapName].playerCount += m.playerstats.length;
    }
  });

  const stats: { [k in string]: MapStats } = {};

  Object.entries(mapStats).forEach(([name, mapStats]) => {
    const aggMap = stats[name];
    if (!aggMap) {
      stats[name] = mapStats;
    } else {
      aggMap.timesPlayed += mapStats.timesPlayed;
      aggMap.timePlayed += mapStats.timePlayed;
      aggMap.kills += mapStats.kills;
      aggMap.suicides += mapStats.suicides;
      aggMap.playerCount += mapStats.playerCount;
      aggMap.dmgDealt += mapStats.dmgDealt;
    }
  });

  return Object.entries(stats).map(([name, s]) => ({ name, ...s, popularity: s.timesPlayed / matches.length }));
}

export function getProp(object: any, property: string) {
  if (!object || !property) {
    return undefined;
  }

  const properties = property.split(".");
  let currentObject = object;
  let index = 0;
  let currentProperty = properties[index];

  while (currentObject.hasOwnProperty(currentProperty)) {
    if (index === properties.length - 1) {
      return currentObject[currentProperty];
    } else {
      currentObject = currentObject[currentProperty];
      if (currentObject === undefined) {
        return undefined;
      }
      index++;
      currentProperty = properties[index];
    }
    if (!currentObject) {
      return undefined;
    }
  }

  return undefined;
}

export function getPropString(object: any, property: string): string {
  const value = getProp(object, property);
  if (typeof value === "undefined" || value === null) {
    return "";
  }
  if (typeof value === "object") {
    return JSON.stringify(value);
  }
  if (value && value.toString) {
    return value.toString();
  }
  return value + "";
}

export function useQuery() {
  const query = new URLSearchParams(useLocation().search);

  function getNewQuery(param: string, value: string | number) {
    const params: string[] = [];

    value = value.toString();

    let contains = false;

    query.forEach((val, key) => {
      if (key === param) {
        contains = true;
        params.push(`${param}=${value}`);
      } else {
        params.push(`${key}=${val}`);
      }
    });

    if (!contains) {
      params.push(`${param}=${value}`);
    }

    return params.join("&");
  }

  return getNewQuery;
}

export function colorIdToName(colorId: number) {
  if (colorId === 0) return "Blue";
  if (colorId === 5) return "Blue (brown)";
  if (colorId === 10) return "Red";
  if (colorId === 11) return "Green";
  if (colorId === 12) return "Gray";
  if (colorId === 13) return "Dark gray";
  if (colorId === 14) return "Dark green";
  if (colorId === 15) return "Brown";
  if (colorId === 16) return "Dark blue";
  if (colorId === 21) return "Light red";
  if (colorId === 23) return "Yellow";

  if (colorId === 24) return "Red/Blue";
  if (colorId === 17) return "Blue/Green";
  if (colorId === 20) return "Blue/Gray";
  if (colorId === 19) return "Red/Gray";
  if (colorId === 8) return "Full Green";
  if (colorId === 7) return "Full Gold";
  if (colorId === 2) return "Full Red";
  if (colorId === 6) return "Full Neon";
  if (colorId === 4) return "Full Black";

  return "N/A";
}

export function getColorStylesFromId(n: number | undefined): { primary: string; secondary: string; css: CSSProperties } {
  let primary = "#6182e7"; // Blue
  let secondary = ""; // Blue

  if (n === undefined) {
    primary = "#6182e7";
  }

  if (n === 0) primary = "#6182e7"; // Blue
  if (n === 5) primary = "#6182e7"; // Blue
  if (n === 10) primary = "#6f1202"; // Dark Red
  if (n === 11) primary = "#a19e5f"; // Green
  if (n === 12) primary = "#a7a7a7"; // Light gray
  if (n === 13) primary = "#646464"; // Dark gray
  if (n === 14) primary = "#625f3b"; // Darkgreen
  if (n === 15) primary = "#b56a42"; // Brown
  if (n === 16) primary = "#1f2745"; // Dark blue
  if (n === 21) primary = "#d73d11"; // Light red
  if (n === 23) primary = "#f9ca04"; // Yellow

  if (n === 24) {
    primary = "#ff3f2a";
    secondary = "#6182e7";
  }
  if (n === 17) {
    primary = "#aaaaff";
    secondary = "#a19e5f";
  }
  if (n === 20) {
    primary = "#aaaaff";
    secondary = "#a7a7a7";
  }
  if (n === 19) {
    primary = "#d73d11";
    secondary = "#a7a7a7";
  }
  if (n === 8) {
    primary = "#a9bd65";
    secondary = "#79874a";
  }
  if (n === 7) {
    primary = "#d9ae00";
    secondary = "#876c00";
  }
  if (n === 2) {
    primary = "#ff5b42";
    secondary = "#bd1900";
  }
  if (n === 6) {
    primary = "#bbf177";
    secondary = "#617c3e";
  }
  if (n === 4) primary = "#000000";

  return {
    primary: primary,
    secondary: secondary,
    css: {
      background: `-webkit-linear-gradient(${primary}, ${secondary || primary})`,
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      color: "transparent",
    },
  };
}

export function killsForColorId(colorId: number) {
  if (colorId === 0) return 0;
  if (colorId === 10) return 0;
  if (colorId === 11) return 0;
  if (colorId === 12) return 0;
  if (colorId === 15) return 0;
  if (colorId === 23) return 0;
  if (colorId === 16) return 0;
  if (colorId === 21) return 0;
  if (colorId === 14) return 0;
  if (colorId === 13) return 0;
  if (colorId === 5) return 0;

  if (colorId === 24) return 1000;
  if (colorId === 17) return 2000;
  if (colorId === 20) return 2000;
  if (colorId === 19) return 3000;
  if (colorId === 8) return 4000;
  if (colorId === 7) return 6000;
  if (colorId === 2) return 8000;
  if (colorId === 6) return 10000;
  if (colorId === 4) return 20000;

  return 10000;
}

export function calculateKillCount(matches: Match[], nickname: string) {
  const killCount: Ratings = {
    duel: 0,
    ffa: 0,
    mayhem: 0,
  };

  matches.forEach((m) => {
    const me = m.playerstats.find((p) => p.nickname === nickname);
    if (me) {
      killCount[determineGameType(m.playerstats.length)] += me.totalkills;
    }
  });

  return killCount;
}

export function getStartOfDayTimestamp() {
  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
  return startOfDay;
}

export function calculateRatingDeltas(players: Player[], matches: Match[]) {
  const todayTimestamp = getStartOfDayTimestamp();

  const matchesToday = matches.filter((m) => m.timestamp >= todayTimestamp);

  const deltas: { [nickname in string]: Ratings } = {};

  matchesToday.forEach((m) =>
    m.ratingsResult.playerRatingResults.forEach((prs) => {
      if (!deltas[prs.nickname]) {
        deltas[prs.nickname] = { ...prs.deltas };
      } else {
        Object.keys(prs.deltas).forEach((eloType: string) => (deltas[prs.nickname][eloType as EloType] += prs.deltas[eloType as EloType]));
      }
    })
  );

  return players.map((p) => ({ nickname: p.nickname, deltas: deltas[p.nickname] || { duel: 0, ffa: 0, mayhem: 0 } }));
}

export function getTicksFromMaxDamage(maxDamage: number) {
  const ticks = [0];

  for (let i = 0; i < Math.ceil(maxDamage / 500); i++) {
    ticks.push((i + 1) * 500);
  }

  return ticks;
}

export function determineGameType(numberOfPlayers: number): keyof Ratings {
  if (numberOfPlayers <= 2) return "duel";
  if (numberOfPlayers <= 4) return "ffa";
  return "mayhem";
}
