import React from "react";
import { BackgroundBox } from "../backgroundBox";

export function AboutPane() {
  return (
    <BackgroundBox header="About Duke DM">
      <div className="header">General</div>
      <div style={{ color: "lightgray", display: "grid", rowGap: "16px", fontSize: "20px" }}>
        <div>
          Duke DM is a modded version of Duke Nukem 3D that tracks players' performance and implements a rating system. A player's rating
          directly affects how powerful he/she is.
        </div>
        <div>
          When dealing damage to an opponent with higher rating than you, your damage to them is increased, and when dealing damage to an
          opponent with lower rating than you, your damage to them is decreased.
        </div>
        <div>
          Your Master Rating is divided into three seperate ratings: One for 1v1 matches, one for matches with 3-4 players and one for
          matches with 5+ players.
        </div>
        <div>To be able to host, port 23513 TCP/UDP needs to be forwarded.</div>
      </div>
      <div className="header" style={{ marginTop: "48px", marginBottom: "24px" }}>
        Default bindings
      </div>
      <ul style={{ color: "lightgray" }}>
        <li style={{ marginBottom: "8px" }}>Left/Right click - Fire/Alternative fire</li>
        <li style={{ marginBottom: "8px" }}>Mouse wheel - Open/Use/Respawn</li>
        <li style={{ marginBottom: "8px" }}>Double mouse wheel - Toggle Jetpack</li>
        <li style={{ marginBottom: "8px" }}>Mouse side button - Throw Flashbang</li>
        <li style={{ marginBottom: "8px" }}>Q - Use Boost</li>
        <li style={{ marginBottom: "8px" }}>E - Pistol</li>
        <li style={{ marginBottom: "8px" }}>R - RPG</li>
        <li style={{ marginBottom: "8px" }}>F - Shotgun</li>
        <li style={{ marginBottom: "8px" }}>V - Chaingun</li>
        <li style={{ marginBottom: "8px" }}>G - Shrinker</li>
        <li style={{ marginBottom: "8px" }}>T - Tripbomb</li>
        <li style={{ marginBottom: "8px" }}>C - Toggle Goggles</li>
        <li style={{ marginBottom: "8px" }}>X - Use invisibility</li>
        <li style={{ marginBottom: "8px" }}>0-9 or mouse wheel scroll - Duke's entire arsenal</li>
        <li style={{ marginBottom: "8px" }}>Left CTRL - Kick</li>
        <li style={{ marginBottom: "8px" }}>Left Shift - Crouch</li>
        <li style={{ marginBottom: "8px" }}>Spacebar - Jump</li>
      </ul>
      <div className="header" style={{ marginTop: "48px", marginBottom: "24px" }}>
        Tips & Tricks
      </div>
      <ul style={{ color: "lightgray" }}>
        <li style={{ marginBottom: "8px" }}>Certain weapons have an alternative firing mode but often costs more ammo to use.</li>
        <li style={{ marginBottom: "8px" }}>Armor reduces damage received by 20-50%.</li>
        <li style={{ marginBottom: "8px" }}>
          When having at least 100 HP, you cannot take lethal damage in one shot. If that were to happen you will instead be saved with 1 HP
          left.
        </li>
        <li style={{ marginBottom: "8px" }}>
          Dealing damage among other things gives you Boost points. When you have 2000, you have 1 minute to activate Boost to gain full HP
          and Fast Movement.
        </li>
        <li style={{ marginBottom: "8px" }}>
          When you get Boost, don't use it right away but instead wait until you are low on HP to get maximum value. You do not lose the
          ability to use Boost when you die. It will automatically be used when the activation time runs out.
        </li>
        <li style={{ marginBottom: "8px" }}>
          When someone on a 3 or higher killstreak dies, they drop a Boost capsule that can be picked up. 3 killstreak drops a blue capsule
          giving 100 Boost point on pickup, 4 killstreak a green one worth 200 Boost points, and 5+ killstreak a red one worth 400 Boost
          points.
        </li>
        <li style={{ marginBottom: "8px" }}>
          If someone has killed you 3 or more times in a row without you killing them, they become your nemesis. Killing your nemesis gives
          300 Boost points.
        </li>
        <li style={{ marginBottom: "8px" }}>
          You automatically gain Boost points over time unless your are the leader. The worse placed you are in the game, the more Boost
          points you gain.
        </li>
        <li style={{ marginBottom: "8px" }}>
          Using Goggles has several advantages:
          <ul>
            <li>Drastically shortens the amount of time you are flashed</li>
            <li>Improve visibility of opponents in low light environments</li>
            <li>Visually reveal opponents footsteps</li>
            <li>Make you able to distinguish between decoys and real opponents</li>
          </ul>
        </li>
        <li style={{ marginBottom: "8px" }}>
          Picking up Atomic Health gives you 50+ HP and allows you to exceed the HP cap of 100 up to 200 HP instead.
        </li>
        <li style={{ marginBottom: "8px" }}>
          By strafe running (using W+A or W+D) you can move a lot faster (41% speed increase) and should always be done when not in direct
          combat.
        </li>
        <li style={{ marginBottom: "8px" }}>
          Unlike other items, the Jetpack decays when not using it which means it will run out by itself. Make sure to use it while it
          lasts.
        </li>
        <li style={{ marginBottom: "8px" }}>The Pistol has perfect accuracy but deals less damage over distance</li>
      </ul>
      <div className="header" style={{ marginTop: "48px", marginBottom: "24px" }}>
        HUD
      </div>
      <img
        src={"https://duke-assets.s3.eu-west-1.amazonaws.com/public/images/demo.png"}
        style={{ width: "1000px", filter: "brightness(130%)" }}
        alt=""
      />
      <div style={{ display: "grid", rowGap: "4px", gridTemplateColumns: "min-content auto", columnGap: "8px", marginTop: "24px" }}>
        <div>1</div>
        <div style={{ color: "lightgray" }}>Current HP</div>
        <div>2</div>
        <div style={{ color: "lightgray" }}>Current ammo for selected weapon</div>
        <div>3</div>
        <div style={{ color: "lightgray" }}>HP/Armor bar</div>
        <div>4</div>
        <div style={{ color: "lightgray" }}>Orange lines indicates current amount of Armor</div>
        <div>5</div>
        <div style={{ color: "lightgray" }}>
          Overview of Duke's arsenal and ammo count. Yellow: Selected weapon. Blue: Acquired weapon. Orange: Unacquired weapon.
        </div>
        <div>6</div>
        <div style={{ color: "lightgray" }}>Current damage dealt</div>
        <div>7</div>
        <div style={{ color: "lightgray" }}>Indicator that opponent had Armor</div>
        <div>8</div>
        <div style={{ color: "lightgray" }}>Indicator that opponent had Goggles turned on</div>
        <div>9</div>
        <div style={{ color: "lightgray" }}>Indicator bar of time left to activate Boost</div>
        <div>10</div>
        <div style={{ color: "lightgray" }}>Boost points bar (currently have 1230/2000)</div>
        <div>11</div>
        <div style={{ color: "lightgray" }}>
          Flashbang count and indicator bar of time left to a new Flashbang is added to the inventory
        </div>
        <div>12</div>
        <div style={{ color: "lightgray" }}>Goggles item with seconds left of usage</div>
        <div>13</div>
        <div style={{ color: "lightgray" }}>Jetpack item with seconds left of usage</div>
        <div>14</div>
        <div style={{ color: "lightgray" }}>Current killstreak of a player</div>
        <div>15</div>
        <div style={{ color: "lightgray" }}>Current number of kills of a player</div>
        <div>16</div>
        <div style={{ color: "lightgray" }}>Fraglimit bar and player's rating</div>
        <div>17</div>
        <div style={{ color: "lightgray" }}>Indicator which player is you and which are your nemesises</div>
        <div>18</div>
        <div style={{ color: "lightgray" }}>Elapsed time</div>
      </div>
    </BackgroundBox>
  );
}
