import React, { useContext, useMemo } from "react";
import { calculateRatingDeltas, getRatingsTimestamp } from "../../lib/functions";
import { BackgroundBox } from "../backgroundBox";
import { EloGraph } from "../eloGraph";
import { DataContext } from "../../lib/context";
import { Table } from "../table";
import { ratingsColumns } from "../../lib/columns";

export function PlayerRatingsPane() {
  const { matches, isFetching, players } = useContext(DataContext);

  const timestamp = useMemo(() => getRatingsTimestamp(matches[0]?.timestamp), [matches]);

  const deltas = useMemo(() => calculateRatingDeltas(players, matches), [matches, players]);

  const mappedPlayers = useMemo(
    () => players.map((p) => ({ ...p, deltas: deltas.find((d) => d.nickname === p.nickname)?.deltas })),
    [players, deltas]
  );

  return (
    <div>
      <BackgroundBox loading={isFetching && matches.length === 0}>
        <div
          style={{
            fontSize: "24px",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <div>Last updated:</div>
          <div>{timestamp}</div>
        </div>
        <EloGraph matches={matches} />
        <div style={{ fontSize: "30px", marginTop: "40px" }}>
          <Table rowKey="nickname" rows={mappedPlayers} columns={ratingsColumns} />
        </div>
      </BackgroundBox>
    </div>
  );
}
