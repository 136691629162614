import React, { useMemo } from "react";
import { getColorStylesFromId } from "../../lib/functions";
import { FragEvent, PlayerGameStats } from "../../lib/models";

interface KillMatrixProps {
  playerStats: PlayerGameStats[];
  fragHistory: FragEvent[];
}

export function KillMatrix(props: KillMatrixProps) {
  const killMatrix = useMemo(() => {
    const matrix: any = {};

    const nicknames = props.playerStats.map((p) => p.nickname).sort() || [];

    nicknames.forEach((killer) => {
      matrix[killer] = {};
      nicknames.forEach((victim) => {
        matrix[killer][victim] = 0;
      });
    });

    props.fragHistory.forEach((f) => matrix[f.killedby][f.nickname]++);

    return matrix;
  }, [props.playerStats, props.fragHistory]);

  return (
    <div
      style={{
        display: "grid",
        rowGap: "8px",
        columnGap: "16px",
        gridTemplateColumns: `min-content repeat(${Object.keys(killMatrix).length},min-content)`,
      }}
    >
      <div>Killer</div>
      {Object.keys(killMatrix).map((nickname) => (
        <div key={nickname}>{nickname}</div>
      ))}
      {Object.keys(killMatrix).map((killer) => (
        <React.Fragment key={killer}>
          <div style={{ ...getColorStylesFromId(props.playerStats.find((ps) => ps.nickname === killer)?.colorid).css }}>{killer}</div>
          {Object.keys(killMatrix).map((innerKiller) => (
            <div key={innerKiller} style={{ textAlign: "center", color: killer === innerKiller ? "red" : "lightgray" }}>
              {killMatrix[killer][innerKiller] || 0}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}
