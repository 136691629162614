import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Bubble } from "./bubble";

interface MenuItemProps {
  text: string;
  path: string;
  highlightPaths: string[];
  bubble?: boolean;
}

export function MenuItem(props: MenuItemProps) {
  const history = useHistory();
  const location = useLocation();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.bubble && <Bubble />}
      <div
        onClick={() => history.push(props.path)}
        className="menu-item big-font-border"
        style={{
          color: props.highlightPaths.includes(location.pathname.split("/")[1]) ? "orange" : undefined,
          marginLeft: props.bubble ? "4px" : undefined,
        }}
      >
        {props.text}
      </div>
    </div>
  );
}
