import React, { useContext, useEffect, useMemo } from "react";
import { Match } from "../../lib/models";
import { calculateWinner, getMatchTimestamp, getPrettyDuration, getPrettyMapName, useQuery } from "../../lib/functions";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { DataContext } from "../../lib/context";

interface MatchHistoryPaneProps {
  isFavorites?: boolean;
}

const matchesPerPage = 15;

export function MatchHistoryPane(props: MatchHistoryPaneProps) {
  const [hoveredMatch, setHoveredMatch] = useState("");
  const [favoriteMatches, setFavoriteMatches] = useState<Match[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();
  const location = useLocation();
  const getNewQuery = useQuery();

  const { matches, mapProperties, favorites } = useContext(DataContext);

  useEffect(() => {
    setFavoriteMatches(matches.filter((m) => favorites.includes(m._id)));
  }, [matches, favorites]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    const pageNumber = params[props.isFavorites ? "fp" : "hp"];
    if (pageNumber) {
      setCurrentPage(Number.parseInt(pageNumber));
    } else {
      setCurrentPage(1);
    }
  }, [location, props.isFavorites]);

  function getCellProps(id: string, rightAlign?: boolean): React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    return {
      style: {
        color: hoveredMatch === id ? "orange" : "lightgray",
        cursor: "pointer",
        textAlign: rightAlign ? "end" : undefined,
      },
      onMouseEnter: () => setHoveredMatch(id),
      onMouseLeave: () => setHoveredMatch(""),
      onClick: () => history.push(`/match/${id}`),
    };
  }

  const matchesToShow = useMemo(
    () => matches.slice((currentPage - 1) * matchesPerPage, currentPage * matchesPerPage),
    [matches, currentPage]
  );

  function getPageNumbers() {
    const pages: number[] = [];

    for (let i = 1; i <= (props.isFavorites ? favoriteMatches.length : matches.length) / matchesPerPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  return (
    <div>
      {props.isFavorites && (
        <div
          style={{
            fontSize: "24px",
            textAlign: "center",
            margin: `60px 0 12px`,
          }}
        >
          Favorites
        </div>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "min-content auto min-content min-content min-content",
          columnGap: "16px",
          fontSize: "18px",
          rowGap: "8px",
          whiteSpace: "nowrap",
        }}
        className="small-font-border"
      >
        <div>Date</div>
        <div>Map</div>
        <div>Duration</div>
        <div style={{ whiteSpace: "nowrap" }}>Players</div>
        <div>Winner</div>
        {(props.isFavorites ? favoriteMatches : matchesToShow).map((m) => (
          <React.Fragment key={m._id}>
            <div {...getCellProps(m._id)}>{getMatchTimestamp(m.timestamp)}</div>
            <div {...getCellProps(m._id)}>{getPrettyMapName(m.map, mapProperties)}</div>
            <div {...getCellProps(m._id, true)}>{getPrettyDuration(m.gametime)}</div>
            <div {...getCellProps(m._id, true)}>{m.playerstats.length}</div>
            <div {...getCellProps(m._id)}>{calculateWinner(m)?.nickname}</div>
          </React.Fragment>
        ))}
      </div>
      <div style={{ marginTop: "24px", display: "flex", userSelect: "none" }}>
        <div style={{ marginRight: "8px", color: "lightgray" }}>Page: </div>
        <div
          key="<"
          style={{
            color: currentPage !== 1 ? "lightgray" : "orange",
            cursor: currentPage !== 1 ? "pointer" : "default",
            marginRight: "8px",
          }}
          onClick={() => {
            if (currentPage !== 1) {
              history.push({ search: getNewQuery(props.isFavorites ? "fp" : "hp", currentPage - 1) });
            }
          }}
        >
          {"<"}
        </div>
        {getPageNumbers().map((p) => (
          <div
            key={p}
            style={{
              color: p === currentPage ? "orange" : "lightgray",
              cursor: p === currentPage ? "default" : "pointer",
              marginRight: "8px",
            }}
            onClick={() => {
              if (p !== currentPage) {
                history.push({ search: getNewQuery(props.isFavorites ? "fp" : "hp", p) });
              }
            }}
          >
            {p}
          </div>
        ))}
        <div
          key=">"
          style={{
            color: currentPage !== getPageNumbers().length ? "lightgray" : "orange",
            cursor: currentPage !== getPageNumbers().length ? "pointer" : "default",
            marginRight: "8px",
          }}
          onClick={() => {
            if (currentPage !== getPageNumbers().length) {
              history.push({ search: getNewQuery(props.isFavorites ? "fp" : "hp", currentPage + 1) });
            }
          }}
        >
          {">"}
        </div>
      </div>
    </div>
  );
}
