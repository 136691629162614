import React, { CSSProperties } from "react";

interface BubbleProps {
  style?: CSSProperties;
}

export function Bubble(props: BubbleProps) {
  return (
    <div
      style={{
        borderRadius: "100px",
        height: "12px",
        width: "12px",
        background: "#27b2f7",
        ...props.style,
      }}
    />
  );
}
