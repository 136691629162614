import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataContext } from "../../lib/context";
import { BackgroundBox } from "../backgroundBox";
import { Button } from "../general/button";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import { APIResources, exclusiveColorIds, IMAGE_S3_URL, startColorIds } from "../../lib/definitions";
import { killsForColorId, colorIdToName, doFetch, getColorStylesFromId } from "../../lib/functions";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import { ProgressBar } from "../general/progressBar";

export function ChangeSkinPane() {
  const [currentColorId, setCurrentColorId] = useState(0);
  const [isChangingColor, setIsChangingColor] = useState(false);

  const { player, playerStats, refresh } = useContext(DataContext);
  const history = useHistory();

  useEffect(() => {
    setCurrentColorId(player?.colorId || 0);
  }, [player]);

  function slideSkin(forward: boolean) {
    const skins = [...startColorIds, ...exclusiveColorIds];
    const indexOfSkin = skins.indexOf(currentColorId);
    const nextIndex = (indexOfSkin + (forward ? 1 : -1)) % skins.length;
    setCurrentColorId(skins[nextIndex === -1 ? skins.length - 1 : nextIndex]);
  }

  const kills = playerStats.find((p) => p.nickname === player?.nickname)?.kills || 0;

  return (
    <BackgroundBox header="Change skin" style={{ userSelect: "none" }}>
      <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr", marginTop: "48px", alignItems: "center", columnGap: "24px" }}>
        <div style={{ textAlign: "end" }}>
          <BsCaretLeftFill style={{ fontSize: "48px", cursor: "pointer" }} onClick={() => slideSkin(false)} />
        </div>
        <img src={`${IMAGE_S3_URL}duke-skins/${currentColorId}.png`} style={{ width: "128px" }} alt="" />
        <div>
          <BsCaretRightFill style={{ fontSize: "48px", cursor: "pointer" }} onClick={() => slideSkin(true)} />
        </div>
      </div>
      <div
        style={{ fontSize: "24px", marginTop: "24px", color: "lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {killsForColorId(currentColorId) > 0 &&
          (killsForColorId(currentColorId) > kills ? (
            <AiFillLock style={{ marginRight: "16px" }} />
          ) : (
            <AiFillUnlock style={{ marginRight: "16px" }} />
          ))}
        {[...startColorIds, ...exclusiveColorIds].map((id) => (
          <div key={id} style={{ ...getColorStylesFromId(id).css, display: currentColorId !== id ? "none" : undefined }}>
            {colorIdToName(id)}
          </div>
        ))}
      </div>
      <div style={{ height: "120px", paddingTop: "36px" }}>
        {kills >= killsForColorId(currentColorId) && currentColorId !== player?.colorId && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "36px",
            }}
          >
            <Button
              text="Select"
              loading={isChangingColor}
              onClick={() => {
                setIsChangingColor(true);
                doFetch("PUT", APIResources.Me, refresh, console.log, () => setIsChangingColor(false), { colorId: currentColorId });
              }}
            />
          </div>
        )}
        {currentColorId === player?.colorId && (
          <div
            style={{
              height: "40px",
              color: "limegreen",
              fontSize: "20px",
              marginBottom: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Current
          </div>
        )}
        {killsForColorId(currentColorId) > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <div style={{ textAlign: "center", marginBottom: "8px" }}>
                Kills {kills}/{killsForColorId(currentColorId)}
              </div>
              <ProgressBar percentage={kills / killsForColorId(currentColorId)} />
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button text="Back" small onClick={() => history.push("/me")} style={{ marginTop: "92px" }} />
      </div>
    </BackgroundBox>
  );
}
