import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";
import { FONT_SIZE_MEDIUM } from "../../lib/definitions";
import { Match } from "../../lib/models";
import {
  calculatePlacementsData,
  getColorStylesFromId,
  getFragPercentageXTicks,
  getPlacementXTicks,
  getPrettyDuration,
} from "../../lib/functions";

interface PlacementGraphProps {
  match: Match;
}

export function PlacementGraph(props: PlacementGraphProps) {
  const [selectedGraphView, setSelectedGraphView] = useState<"Kills" | "Placements">("Kills");
  const [calculatedPlacements, setCalculatedPlacements] = useState<{
    placementData: { nickname: string; placement: number; gametimecounter: number }[];
    killsData: { nickname: string; score: number; gametimecounter: number }[];
    minScore: number;
  }>();

  useEffect(() => {
    if (!props.match) {
      return;
    }

    setCalculatedPlacements(calculatePlacementsData(props.match));
  }, [props.match]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ display: "flex", fontSize: FONT_SIZE_MEDIUM }}>
          <div
            style={{
              color: selectedGraphView === "Kills" ? "orange" : "lightgrey",
              cursor: "pointer",
              marginRight: "60px",
            }}
            onClick={() => setSelectedGraphView("Kills")}
          >
            Kills
          </div>
          <div
            style={{
              color: selectedGraphView === "Placements" ? "orange" : "lightgrey",
              cursor: "pointer",
            }}
            onClick={() => setSelectedGraphView("Placements")}
          >
            Placements
          </div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={250}>
        <LineChart
          data={selectedGraphView === "Kills" ? calculatedPlacements?.killsData : calculatedPlacements?.placementData}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
          <XAxis
            tickFormatter={(t) => getPrettyDuration(t / 30)}
            dataKey="gametimecounter"
            domain={[0, props.match.fraghistory[props.match.fraghistory.length - 1].gametimecounter]}
            ticks={getFragPercentageXTicks(props.match.gametime)}
            type="number"
          />
          {selectedGraphView === "Kills"
            ? [
                <YAxis
                  key="yaxis"
                  domain={[calculatedPlacements?.minScore || 0, props.match.fraglimit]}
                  type="number"
                  allowDecimals={false}
                />,
                <ReferenceLine key="ref1" y={0} stroke="#595959" strokeWidth="2" />,
                <Tooltip key="tooltip" labelFormatter={(l) => getPrettyDuration(l, true)} />,
                props.match.playerstats.map((p) => (
                  <Line
                    key={p.nickname}
                    type="monotone"
                    dataKey={p.nickname}
                    stroke={getColorStylesFromId(p.colorid).primary}
                    dot={false}
                  />
                )),
              ]
            : [
                <YAxis
                  ticks={getPlacementXTicks(props.match.playerstats.length)}
                  domain={[props.match.playerstats.length, 1]}
                  type="number"
                  reversed
                  key="yaxis2"
                />,
                <Tooltip labelFormatter={(l) => getPrettyDuration(l, true)} key="tooltip2" />,
                props.match.playerstats.map((p) => (
                  <Line
                    key={p.nickname}
                    type="monotone"
                    dataKey={p.nickname}
                    stroke={getColorStylesFromId(p.colorid).primary}
                    dot={false}
                  />
                )),
              ]}
          <Legend />,
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
