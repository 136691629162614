import React, { useState } from "react";
import { AiFillLock } from "react-icons/ai";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import { APIResources, exclusiveColorIds, IMAGE_S3_URL, startColorIds } from "../../lib/definitions";
import { colorIdToName, doFetch, getColorStylesFromId, killsForColorId } from "../../lib/functions";
import { BackgroundBox } from "../backgroundBox";
import { Button } from "../general/button";

interface CreateDukeProfilePaneProps {
  onBack(): void;
  onSuccess(): void;
}

export function CreateDukeProfilePane(props: CreateDukeProfilePaneProps) {
  const [nickname, setNickname] = useState("");
  const [currentColorId, setCurrentColorId] = useState(startColorIds[0]);
  const [sliderValue, setSliderValue] = useState(1150);
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function slideSkin(forward: boolean) {
    const skins = [...startColorIds, ...exclusiveColorIds];
    const indexOfSkin = skins.indexOf(currentColorId);
    const nextIndex = (indexOfSkin + (forward ? 1 : -1)) % skins.length;
    setCurrentColorId(skins[nextIndex === -1 ? skins.length - 1 : nextIndex]);
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        columnGap: "48px",
      }}
    >
      <div />
      <BackgroundBox header="New profile">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "400px" }}>
            <div style={{ color: "white", margin: "36px 0 8px" }}>Ingame nickname (Cant be changed)</div>
            <input
              placeholder="Nickname..."
              value={nickname}
              onChange={(e) => /^[A-z0-9_]{0,10}$/.test(e.target.value) && setNickname(e.target.value.toUpperCase())}
              className="input"
              style={{ fontSize: "24px", marginBottom: "36px" }}
            />
            <div style={{ color: "white", marginBottom: "8px" }}>Player skin</div>
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr", alignItems: "center", columnGap: "24px", userSelect: "none" }}
            >
              <div style={{ textAlign: "end" }}>
                <BsCaretLeftFill style={{ fontSize: "48px", cursor: "pointer" }} onClick={() => slideSkin(false)} />
              </div>
              <img src={`${IMAGE_S3_URL}duke-skins/${currentColorId}.png`} style={{ width: "128px" }} alt="" />
              <div>
                <BsCaretRightFill style={{ fontSize: "48px", cursor: "pointer" }} onClick={() => slideSkin(true)} />
              </div>
            </div>
            <div
              style={{
                fontSize: "24px",
                marginTop: "24px",
                color: "lightgray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {killsForColorId(currentColorId) > 0 && <AiFillLock style={{ marginRight: "16px" }} />}
              {[...startColorIds, ...exclusiveColorIds].map((id) => (
                <div key={id} style={{ ...getColorStylesFromId(id).css, display: currentColorId !== id ? "none" : undefined }}>
                  {colorIdToName(id)}
                </div>
              ))}
            </div>
            <div style={{ color: "white", margin: "36px 0 8px" }}>Start rating</div>
            <div style={{ color: "white", marginBottom: "8px", fontSize: "14px" }}>
              The higher the rating the less damage you will do. It is recommended that inexperienced players choose the lowest value.
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <input
                  type="range"
                  min="800"
                  max="1500"
                  step="1"
                  value={sliderValue}
                  onChange={(e) => setSliderValue(Number.parseInt(e.target.value))}
                  style={{ width: "300px" }}
                />
                <div style={{ marginLeft: "24px", fontSize: "24px" }}>{sliderValue}</div>
              </div>
            </div>
            {errorMessage && <div style={{ color: "red", marginTop: "16px" }}>{errorMessage}</div>}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around", margin: `${errorMessage ? "24px" : "48px"} 0 12px` }}>
          <Button text="Back" onClick={props.onBack} />
          <Button
            text="Create"
            disabled={!/^[A-z0-9_]{3,10}$/.test(nickname) || killsForColorId(currentColorId) !== 0}
            loading={isCreating}
            onClick={() => {
              setIsCreating(true);
              doFetch("POST", APIResources.Me, props.onSuccess, setErrorMessage, () => setIsCreating(false), {
                nickname: nickname,
                startRating: sliderValue,
                colorId: currentColorId,
              });
            }}
          />
        </div>
      </BackgroundBox>
      <div style={{ height: "200px" }} />
    </div>
  );
}
